 import React, {useRef, useState} from "react";

 const FotosLandPageSlide = (props)=>{
    const [position, setPosition] = useState(0); // Initial position
    const slideFotosElement = useRef(null);
    const bgFotosElement = useRef(null);


    const clickMoveFotoBtn = (x) => {
      
        let widthSlideFotosElement;
        let widthBgFotosElement;
        const slideEl = slideFotosElement.current;
        const bgFotosEl = bgFotosElement.current;

        if (slideEl && bgFotosEl) {
            widthSlideFotosElement = parseInt(slideEl.getBoundingClientRect().width);
            widthBgFotosElement = -(parseInt(bgFotosEl.getBoundingClientRect().width));
        }
        setPosition(position + x);

        if (position > 0) {
            /*foward '>' position negative*/
            setPosition(0);
        }

        if ((position + widthSlideFotosElement + 600) < 0) {
            console.log('Menor q width', position)
            setPosition(0);
        }
    }

    const fotos = (fotosUrl)=>{

        const fotosElement = fotosUrl.map((item)=>(
            <figure style={{ transform: `translate(${position}px)` }}>
                
                <img src={item} alt='Curso de Inglês e Informática, sala de aula Seta Cursos'/>
            </figure>
        ))
        return fotosElement;

    }



    return (

        <div class='bgFotos' id='fotosInformática' ref={bgFotosElement} data-aos='flip-left' >
        <button class='btnFotos' id='btnFotoBackward' onClick={() => clickMoveFotoBtn(600)} >&#8249;</button>



        <div id='slideFotos' ref={slideFotosElement}>
     
         
        {fotos(props.fotosUrl)}



        </div>
        <button class='btnFotos' onClick={() => clickMoveFotoBtn(-600)} id='btnFotoFoward'>&#8250;</button>

    </div>

    )
 }

 export default FotosLandPageSlide;