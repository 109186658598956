import React, { useState,  useEffect } from 'react';

import { Helmet } from 'react-helmet';
import 'bootstrap-icons/font/bootstrap-icons.css';

import AOS from 'aos';
import 'aos/dist/aos.css'

import FormContato from '../../../FormContato.js';
import FotosLandPageSlide from '../FotosLandPageSlide.js';
import FormLeadVertical from '../FormLeadVertical.js';


import foto01 from "../../../../../assets/img/informatica/fotos/01-min.jpg";
import foto02 from "../../../../../assets/img/informatica/fotos/02-min.jpg";
import foto03 from "../../../../../assets/img/informatica/fotos/03-min.jpg";
import foto04 from "../../../../../assets/img/informatica/fotos/04-min.jpg";
import foto05 from "../../../../../assets/img/informatica/fotos/05-min.jpg";
import foto06 from "../../../../../assets/img/informatica/fotos/06-min.jpg";
import foto07 from "../../../../../assets/img/informatica/fotos/07-min.jpg";
import foto08 from "../../../../../assets/img/informatica/fotos/08-min.jpg";
import foto09 from "../../../../../assets/img/informatica/fotos/09-min.jpg";
import foto11 from "../../../../../assets/img/informatica/fotos/11-min.jpg";
import foto12 from "../../../../../assets/img/informatica/fotos/12-min.jpg";
import foto13 from "../../../../../assets/img/informatica/fotos/13-min.jpg";
import InglesCompleto from './_InglesCompleto.js';
import InglesKids from './_InglesKids.js';

import NavCursosPorCategoria from './NavCursosPorCategoria.js';


const InglesLandingPage = () => {

    const [dataCurso, setDataCurso] = useState({});

    const [paginaCurso, setPaginaCurso] = useState(null);



    const handleCallbackValorCurso = (data) => {
        setDataCurso(data);
        
      };
    

    useEffect(() => {
      

        const trocaPaginaCurso = () => {
            // Lógica para determinar qual componente carregar com base na URL
          
            const pathname = window.location.href;
    
            const url = pathname.split('?')[0]; // Remove everything after '?' including '?'
               
            if (url.includes('/ingles-completo')) {
                
               setPaginaCurso(<InglesCompleto callback={handleCallbackValorCurso} />);
            } else if (url.includes('/ingles-kids')) {
               

               setPaginaCurso(<InglesKids callback={handleCallbackValorCurso}/>);
            } 
           
            else {
            
                const itemCursoArr =  document.querySelectorAll('.item-curso-ingles-completo');
                itemCursoArr.forEach((item)=>{
                 item.classList.add('item-curso-active');
                }); 
               setPaginaCurso(<InglesCompleto callback={handleCallbackValorCurso} />);

            }
           
                     }

                     trocaPaginaCurso();   
           

        AOS.init({ duration: 1000 });


    }, []);


    return (

        <div id='bg-landing-page-ingles' className="bg-landing-page">
 <Helmet>
   
   <meta name="robots" content="index, follow" />

   {/* Adicione mais meta tags conforme necessário */}
 </Helmet>
            <section class='landing-page' id='landing-page-ingles'>

                <FotosLandPageSlide fotosUrl={[
                    foto01, foto02, foto03, foto04, foto05, foto06, foto07,
                    foto08, foto09, foto11, foto12, foto13,
                ]} />

                <NavCursosPorCategoria/>

                <h1 id='sessao-content-landingpage' style={{ display: 'hidden' }} ></h1>

                <div id='bg-main-content-landing-page ' className='bg-main-content-landing-page'>

                    <div id='main-content-landing-page' class='main-content-landing-page' data-aos='zoom-in-up' data-aos-duration="4000">
                   
                        {paginaCurso}

                    </div>{/*mainContent */}

                    <div class='bg-side-menu' >

                        < FormLeadVertical descontoPorcento='10' preco='160,00' precoFinal='144,00' />

                    </div>{/*sideMenu */}

           </div>
 {/* bg-main-content-landing-page */}

 <div id='bg-contato-curso' class='data-aos-form-horizontal' data-aos='zoom-in-up'>
                            <span style={{ opacity: 0 }} id='sessao-form-contato'>sessao-form-contato</span>
 <FormContato possuiDesconto={dataCurso.possuiDesconto} descontoPorcento={dataCurso.desconto}  preco={dataCurso.valor} precoFinal={dataCurso.valorFinal}/>
           </div>
            </section>

           

        </div>

    )
}


export default InglesLandingPage; 
