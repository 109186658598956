import React, {useState, useEffect} from "react";
import Icon from "../../../../../assets/icons/index.js";

import AOS from 'aos';
import 'aos/dist/aos.css'

import ImgInformaticaRapida from '../../../../../assets/img/informatica/img-curso-informatica-rapida.png';


import AutonomiaEscritorio from "./modulos_informatica_rapida/AutonomiaEscritorio.js";

import Windows from "./modulos_informatica_rapida/Windows.js";
import Office from "./modulos_informatica_rapida/Office.js";
import Internet from "./modulos_informatica_rapida/Internet.js";
import Rede from "./modulos_informatica_rapida/Rede.js";

import Digitacao from "./modulos_informatica_rapida/Digitacao.js";

const InformaticaRapida = ({ callback })=>{
const [dataCurso, setDataCurso] = useState({});



    const showHideBannerInformatica = ()=>{
        const banner = document.getElementsByClassName('banner-sobre-curso')[0];
        banner.style.display='block'; 
    }

useEffect(()=>{
    const data = {
        possuiDesconto: 'não',
        valor: '220,00',
        desconto: '10',
        valorFinal: '198,00',
    };
    setDataCurso(data)
    callback(dataCurso);

    showHideBannerInformatica();

    AOS.init({ duration: 1000 });

},[]); 

return (
     <div className="bg-curso-informatica" id='bg-curso-informatica-rapida'>


                            <div id="bg_resumo_informatica" className="resumo-curso">

                                    <h1 className="title">Informática Rápida - <span>Adultos</span></h1>

                                                      <figure className="bg-curso-image">

<img src={ImgInformaticaRapida}/>

</figure>

                                    
<p>
O Curso de INFORMÁTICA RÁPIDA para ADULTOS possuir duração de 3 meses 
sendo especialmente 
desenvolvido para atender às necessidades do público adulto que busca acelerar 
sua inserção no mundo da informática. </p>
<p>Concentrando-se no essencial, o curso 
aborda de forma direta e personalizada, desde conceitos 
fundamentais até habilidades relevantes para o mercado de trabalho.
    </p>
   

                                    <div id='visao-geral-curso'>
                                        <h3>VISÃO GERAL </h3>

                                        <li>

                                            <Icon name="calendar" className="icon calendar" />
                                            Tempo de Duração: 3 meses</li>
                                        <li>

                                            <Icon name="class2" className="icon class2" />
                                            Aulas por Semana: 2 (2 horas p/ aula)</li>
                                        <li>

                                            <Icon name="time-duration" className="icon time-duration" />

                                            Carga Horária:  48 horas </li>
                                        <li>
                                            <Icon name="certificate" className="icon certificate" />
                                            Certificado de qualificação profissional incluso!</li>



                                        <li>
                                            <Icon name="books" className="icon books" />
                                            Material didático incluso!

                                        </li>
                                        <li>
                                            <Icon name="moeda-real" className="icon moeda-real" />
                                            Investimento: R$ {dataCurso.valor} (mensal)
                                        </li>


                                    </div>



                            </div>

                            <div id="modulos-curso-informatica">
                                <h3>Módulos recomendados!</h3>
                               
                                <AutonomiaEscritorio/>
                                <Office />
                                <Windows />
                               
                                
                            </div>
                        </div>

)

}

export default InformaticaRapida;