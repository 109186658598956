import React from "react";


import imgPraticaConversacao from "../../../../../../assets/img/ingles/curso-ingles-kids-pratica-conversacao-seta-cursos.png";

const PraticaConversacao = () => {


    return (

        <div id="modulo-pratica-conversacao" className="modulo-curso modulos-ingles" data-aos='fade-right'>


            <figure>

                <img src={imgPraticaConversacao} alt='Curso de inglês kids, aulas práticas com conversação desde o primeiro dia.' />
            </figure>


            <div className="resumo">

                <h2>Prática e Conversação</h2>

           

                    <p>Estímulo à prática de conversação em situações simuladas e interações sociais, desenvolvendo a confiança das crianças em usar o idioma inglês.</p>

            </div>


        </div>



    )
}

export default PraticaConversacao;
