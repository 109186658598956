import React from "react";

const Article = (article) => {
  return (
    <div className="article">
      <h1>{article.title}</h1>
      <div>{article.content}</div>
    </div>
  );
};

export default Article;