import React, { Component } from "react";
import { Animated } from "react-animated-css";

const Internet = ()=>{

    return (
      <div className="modulos_informatica " id="modulo-internet" data-aos='fade-right'>
        <div className="modulo_content">
          <div className="bloco_1">

              <h1>Internet</h1>
         
 
              <div className="resumo-content">

     

                <p>
                Neste módulo, oferecemos uma introdução abrangente aos conceitos essenciais da internet. 
                Os alunos terão a oportunidade de se familiarizar com termos importantes, como Download, Upload, navegação, links, 
                Cookies e extensões, Além disso, abordamos princípios de segurança e fornecemos 
                diretrizes para práticas seguras no ambiente online.

             
              

                </p>
                <figure>

                <img
                  src={require("../../../../../../assets/img/informatica/modulo_internet.png")}
                />
                </figure>

              </div>
        
   
          </div>
       
        </div>
      </div>
    );
  
}

export default Internet;
