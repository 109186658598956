import React, { Component } from "react";
import { Animated } from "react-animated-css";

import ImgPowerBI from "../../../../../../assets/img/powerbi/img-powerbi.png"; 

const PowerBI = () => {
    return (
      <div className="modulos_informatica " id="modulo-powerbi">
        <div className="modulo_content">
          <div className="bloco_1">
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1500}
            >
              <h1>Power BI</h1>
            </Animated>
            <Animated
              animationIn="slideInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1000}
            >
              <div className="resumo">
                <p>
                
O módulo de Power BI no curso de informática avançado ensina aos alunos 
a utilizar eficientemente essa ferramenta para análise de dados. 
Os participantes aprendem a importar, transformar e visualizar dados, 
construir modelos de dados, criar relatórios dinâmicos e interativos, 
além de explorar a integração com outras ferramentas Microsoft. 
Ao final do curso, os alunos estão aptos a transformar dados em insights 
valiosos para tomada de decisões informadas.
                  <Animated
                animationIn="slideInRight"
                animationOut="fadeOut"
                isVisible={true}
                animationInDuration={1000}
              >
             <img src={ImgPowerBI}  />
              </Animated>
                </p>
         
              </div>
            </Animated>
            <Animated
              animationIn="fadeInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1200}
            >
              <div className="conteudo">
                <h3>Conteúdo Programático</h3>
                <ul>
                  <span className="span_office">
                   
                    <li>Importação de dados</li>
  <li>Transformação de dados</li>
  <li>Visualização de dados</li>
  <li>Construção de modelos de dados</li>
  <li>Criação de relatórios dinâmicos e interativos</li>
  <li>Medidas personalizadas</li>
  <li>Criação de painéis informativos</li>
  <li>Integração do Power BI com outras ferramentas Microsoft</li>
  <li>Fluxo de trabalho eficiente</li>
  <li>Utilização avançada do Power BI</li>
                  </span>
                </ul>
              </div>
            </Animated>
          </div>
          {/*bloco 1 */}

      
        </div>
      </div>
    );
    }

export default PowerBI;
