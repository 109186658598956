import React, { Component } from "react";
const  NavInformaticaAvancado = ()=>{
 const displayModulos = (e) => {
    let a_element = document.getElementsByClassName("a_element");

    for (let i = 0; i < a_element.length; i++) {
      a_element[i].classList.remove("active");
    }

    let a_name = e.target.name;
    e.target.classList.add("active");

    let modulo = document.querySelector("#modulo-" + a_name);
    var modulos = document.getElementsByClassName("modulos_informatica");
    for (var i = 0; i < modulos.length; i++) {
      modulos[i].style.display = "none";
    }

    modulo.style.display = "block";
  }
  return (
    <nav id="nav_informatica">
      <ul>
        <li id="windows">
          <a
            className="a_element active"
            name="windows"
            onClick={displayModulos}
          >
            Windows 10
          </a>
        </li>

        <li id="office-avancado">
          <a
            className="a_element"
            name="office-avancado"
            onClick={displayModulos}
          >
            Office Avançado
          </a>
        </li>
        <li id="PowerBI">
          <a
            className="a_element"
            name="powerbi"
            onClick={displayModulos}
          >
           Power BI
          </a>
        </li>
        <li id="hardware">
          <a
            className="a_element"
            name="hardware"
            onClick={displayModulos}
          >
            {" "}
            Hardware
          </a>
        </li>
        <li id="redes">
          <a
            className="a_element"
            name="rede"
            onClick={displayModulos}
          >
            {" "}
            Redes
          </a>
        </li>

        <li id="internet">
          <a
            className="a_element"
            name="internet"
            onClick={displayModulos}
          >
            {" "}
            Internet Avançada
          </a>
        </li>

    




      </ul>
    </nav>
  );



} 




 
 

export default NavInformaticaAvancado;
