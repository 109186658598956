import React, { Component } from "react";
class NotFound404 extends Component {
  state = {};
  render() {
    return (
      <div>
        <h1>404 Not Found</h1>
      </div>
    );
  }
}

export default NotFound404;
