import React, { useEffect } from "react";
import { Animated } from "react-animated-css";
import imgOffice from "../../../../../../assets/img/informatica/img-modulo-office.png"; 


import AOS from 'aos';
import 'aos/dist/aos.css'


const OfficePratico = ()=>{

  
  useEffect(()=>{
    AOS.init({ duration: 1000 });
  
  }, [])

    return (
      <div className="modulos_informatica " id="modulo-office" data-aos='fade-left' >
        <div className="modulo_content">
          <div className="bloco_1">
      
              <h1>Office</h1>

              <div className="resumo-content">
                <p>
                Nosso curso direciona uma atenção especial ao pacote Office, considerado um dos 
                requisitos essenciais pelo mercado. Neste módulo, os alunos 
                aprendem de maneira simples e direta, por meio de exemplos práticos e aplicáveis.
                </p>
                <figure>
         
                <img src={imgOffice}
                />
                </figure>
      
              </div>

        
          </div>
          {/*bloco 1 */}

      
        </div>
      </div>
    );
  }


export default OfficePratico;
