import React, { useState, useEffect, Suspense, lazy } from 'react';
import NotFound404 from './public/NotFound404.js';
import Homepage from './public/Homepage.js';

const Index = () => {
  const [componentName, setComponentName] = useState('Homepage');

  useEffect(() => {
    // Extract the component name from the URL
    const urlParts = window.location.pathname.split('/');
    const name = urlParts[urlParts.length - 1];
    setComponentName(name);
  }, []);

  let DynamicComponent;

  if (componentName) {
    DynamicComponent = lazy(() =>
      import(`./public/${componentName}`).catch(() => {
        return { default: NotFound404 }; // Ensure that it resolves to NotFound404
      })
    );
  } else {
    DynamicComponent = Homepage;
  }

  return (
    <div id="index-blog">
      <div id="bg-pages-blog">
        <h1>Index</h1>
        <Suspense fallback={<div>Loading...</div>}>
          <DynamicComponent />
        </Suspense>
      </div>
    </div>
  );
};

export default Index;
