import React, { Component } from "react";
const  NavInformaticaAvancado = ()=>{
 const displayModulos = (e) => {
    let a_element = document.getElementsByClassName("a_element");

    for (let i = 0; i < a_element.length; i++) {
      a_element[i].classList.remove("active");
    }

    let a_name = e.target.name;
    e.target.classList.add("active");

    let modulo = document.querySelector("#modulo-" + a_name);
    var modulos = document.getElementsByClassName("modulos_informatica");
    for (var i = 0; i < modulos.length; i++) {
      modulos[i].style.display = "none";
    }

    modulo.style.display = "block";
  }
  return (
    <nav id="nav_informatica">
      <ul>
      <li id="intro-tec-kids">
          <a
            className="a_element active"
            name="intro-tec-kids"
            onClick={displayModulos}
          >
           Introdução a Tecnologia
          </a>
        </li>
     
        <li id="windows">
          <a
            className="a_element"
            name="windows"
            onClick={displayModulos}
          >
           Windows Kids
          </a>
        </li>

        <li id="componentes-kids">
          <a
            className="a_element"
            name="componentes-kids"
            onClick={displayModulos}
          >
           Computador e seus Componentes
          </a>
        </li>

        <li id="navegacao-kids">
          <a
            className="a_element"
            name="navegacao-kids"
            onClick={displayModulos}
          >
          
          Navegando com Segurança 
            
          </a>
        </li>




        <li id="programacao-kids">
          <a
            className="a_element"
            name="programacao-kids"
            onClick={displayModulos}
          >
          
          Programação Kids
            
          </a>
        </li>      
        <li id="jogos-educativos">
          <a
            className="a_element"
            name="jogos-educativos"
            onClick={displayModulos}
          >
          
          Jogos Educativos
          </a>
        </li>      
        
        <li id="aplicacoes-criativas">
          <a
            className="a_element"
            name="aplicacoes-criativas"
            onClick={displayModulos}
          >
          
          Aplicações Criativas
          
            
          </a>
        </li>

     

    




      </ul>
    </nav>
  );



} 




 
 

export default NavInformaticaAvancado;
