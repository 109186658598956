import React, { useState, useRef, useEffect } from 'react';


import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

import ImgThumbNavInformaticaEssencial from '../../../../../assets/img/informatica/curso-informatica-essencial-seta-cursos-thumb.png';
import ImgThumbNavInformaticaAvancado from '../../../../../assets/img/informatica/curso-informatica-avancado-seta-cursos-thumb.png';
import ImgThumbNavInformaticaPratica from '../../../../../assets/img/informatica/curso-informatica-pratica-seta-cursos-thumb.png';
import ImgThumbNavInformaticaRapida from '../../../../../assets/img/informatica/curso-informatica-rapida-seta-cursos-thumb.png';
import ImgThumbNavInformaticaKids from '../../../../../assets/img/informatica/curso-informatica-kids-seta-cursos-thumb.png';



const NavCursosPorCategoria = () => {

    const addClassItemActiveNavCurso = ()=>{
     
       const pathname = window.location.href;
    
        const url = pathname.split('?')[0]; // Remove everything after '?' including '?'
       
        
    const urlArr = url.split('/');
    const lastItemUrl = urlArr[urlArr.length - 1];
    console.log(lastItemUrl)
/*
    const navItemActive = document.getElementById('item-curso-'+lastItemUrl);
    navItemActive.classList.add('item-curso-active');
    */
    const navItemActive = document.querySelectorAll('.item-curso-'+lastItemUrl);
    console.log(navItemActive)

    navItemActive.forEach((item)=>{
        item.classList.add('item-curso-active');
    })

    }


    useEffect(()=>{


addClassItemActiveNavCurso();



},[])

/*
    const btnCursosRef = [useRef(), useRef(), useRef(), useRef(), useRef()];
    const handleButtonClick2 = (value) => {
        handleButtonClickShowComponent(value)
        let btn = btnCursosRef[value - 1];
        addClassNameActiveButtom(btn.current);
    }
    const addClassNameActiveButtom = (btn) => {

        let itemCurso = document.getElementsByClassName('item-curso');

        for (var i = 0, len = itemCurso.length; i < len; i++) {
            itemCurso[i].classList.remove('item-curso-active');

        }

        
        let cursoName = btn.getAttribute("name");
        
        var elements2 = document.getElementsByClassName(cursoName);
        for (var i = 0, len = elements2.length; i < len; i++) {
            //elements2[i].classList.add('active')
            elements2[i].closest('.item-curso').classList.add('item-curso-active');

        }


    }
*/

    return (
        <div id='bg-nav-cursos-por-categoria' className='bg-nav-cursos-por-categoria'>
            <h2>Conheça nossos <strong>CURSOS DE INFORMÁTICA</strong></h2>
{/*

*/}
            <div  className='item-curso-informatica-rapida item-curso item-curso-informatica'>
                <a href='/curso-informatica/informatica-rapida' >
                    <figure>                        
                        <img src={ImgThumbNavInformaticaRapida} alt='curso-informatica-rapida-seta-cursos' />
                        <figcaption>
                        Informática Rápida <strong>Adultos</strong>
                        </figcaption>
                    </figure>
                </a>
            </div>

            <div  className='item-curso-informatica-pratica item-curso item-curso-informatica'>
                <a href='/curso-informatica/informatica-pratica'>
                    <figure>
                    <img src={ImgThumbNavInformaticaPratica} alt='curso-informatica-pratica-seta-cursos'/>
                    <figcaption>
                    Informática Prática <strong>Adultos</strong>
                    </figcaption>
                    </figure>
                   
                </a>
            </div>

            <div  className='item-curso-informatica-essencial item-curso item-curso-informatica'>
                <a href='/curso-informatica/informatica-essencial'>
                    <figure>
                        <img src={ImgThumbNavInformaticaEssencial} alt='curso-informatica-essencial-seta-cursos' />
                        <figcaption>
                        Informática <strong>Essencial</strong>
                        </figcaption>
                    </figure>           
                </a>
            </div>

            <div  className='item-curso-informatica-avancado item-curso item-curso-informatica'>
                <a href='/curso-informatica/informatica-avancado' >
                    <figure>
                        <img src={ImgThumbNavInformaticaAvancado} alt='curso-informatica-avancado-seta-cursos' />
                        <figcaption>
                        Informática <strong>Avançado</strong>
                        </figcaption>
                    </figure>

                </a>

            </div>

            <div  className='item-curso-informatica-kids item-curso item-curso-informatica'>
                <a href='/curso-informatica/informatica-kids'>
                    <figure>
                        <img src={ImgThumbNavInformaticaKids} alt='curso-informatica-kids-seta-cursos'/>
                        <figcaption>
                        Informática <strong>KIDs</strong>
                        </figcaption>
                    </figure>
                </a>
            </div>

        </div>


    )

}
export default NavCursosPorCategoria;