import React from "react";

import ContatoHome from "../../ContatoHome.js"; 
import ImgManutencao from "../../../../assets/img/pagina-em-manutencao.png"; 

import ImgADM from "../../../../assets/img/img_painel_cursos-assets/ADM@0.25x.png"; 
import ImgBD from "../../../../assets/img/img_painel_cursos-assets/BD@0.25x.png"; 
import ImgENG from "../../../../assets/img/img_painel_cursos-assets/ENG@0.25x.png"; 
import ImgEXCEL from "../../../../assets/img/img_painel_cursos-assets/EXCEL@0.25x.png"; 
import ImgWEB from "../../../../assets/img/img_painel_cursos-assets/WEB@0.25x.png"; 
import ImgINFO from "../../../../assets/img/img_painel_cursos-assets/INFO@0.25x.png"; 

import ImgAPESC from "../../../../assets/img/img_painel_cursos-assets/APESC@0.25x.png"; 
import ImgESP from "../../../../assets/img/img_painel_cursos-assets/ESP@0.25x.png"; 
import ImgPOWERBI from "../../../../assets/img/img_painel_cursos-assets/POWERBI@0.25x.png"; 
import ImgMD from "../../../../assets/img/img_painel_cursos-assets/MD@0.25x.png"; 
import ImgPROG from "../../../../assets/img/img_painel_cursos-assets/PROG@0.25x.png"; 


const ManutencaoLandPage = ()=>{


return(
    <div className='bg-land-pages'>
        <section class='land-page' id='manutencao-land-page'>
    
        <img className='img-pagina-manutencao' src={ImgManutencao} />
        <div className="cursos-manutencao-pagina">

            <h1>Conheça um pouco mais dos nossos cursos!</h1>            
        <img src={ImgAPESC}/>    
            <img src={ImgADM}/>
            <img src={ImgWEB}/>
            <img src={ImgBD}/>

            <img src={ImgPROG}/>
         
            <img src={ImgPOWERBI}/>

            <img src={ImgEXCEL}/>
            <img src={ImgINFO}/>

            <img src={ImgENG}/>

            <img src={ImgESP}/>
            <img src={ImgMD}/>
           
            

        </div>
<ContatoHome/>
        </section>

    </div>
)
}

export default ManutencaoLandPage; 