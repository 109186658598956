import React, {useEffect} from "react";
import Icon from "../../../../../assets/icons/index.js";

import NavInformaticaAvancado from "./modulos_informatica_avancado/navInformaticaAvancado.js";
import ImgInformatica02 from '../../../../../assets/img/informatica/img-curso-informatica-02.png';


import Hardware from "./modulos_informatica_avancado/Hardware.js";

import PowerBI from "./modulos_informatica_avancado/PowerBI.js";
import Windows from "./modulos_informatica_avancado/Windows.js";
import OfficeAvancado from "./modulos_informatica_avancado/OfficeAvancado.js";
import Internet from "./modulos_informatica_avancado/Internet.js";
import Rede from "./modulos_informatica_avancado/Rede.js";


const InformaticaEssencial = ({ callback })=>{

    
    const showHideBannerInformatica = ()=>{
        const banner = document.getElementsByClassName('banner-sobre-curso')[0];
        banner.style.display='block'; 
    }

useEffect(()=>{

    const data = {
        possuiDesconto: 'não',
        valor: '160,00',
        desconto: '10',
        valorFinal: '144,00',
    };


    callback(data);

    showHideBannerInformatica();
},[]); 
return (
  
     <div className="bg-curso-informatica" id='bg-curso-informatica-avancado'>
                            <div id="bg_resumo_informatica" className="resumo-curso">

                               
                                    <h1 className="title">Informática Avançado</h1>
                                    <figure className="bg-curso-image">
                                        <img src={ImgInformatica02} />
                                    </figure>

                                    <p>Explore os fundamentos da informática com nosso curso completo! Seja você um iniciante ou alguém em busca de aprimoramento,
                                        nosso curso de INFORMÁTICA COMPLETO é projetado para atender a todos os níveis de conhecimento,
                                        tornando-o acessível e envolvente para todos.</p>

                                    <p>Nosso ambiente de aprendizado é acolhedor e prático, repleto de dicas valiosas, proporcionando a você a
                                        confiança necessária para se aventurar no mundo digital. Ao longo do curso, você terá a oportunidade de
                                        explorar e aplicar os conhecimentos adquiridos, preparando-o para enfrentar os desafios e oportunidades que a tecnologia oferece.</p>

                                    <div id='visao-geral-curso'>
                                        <h4>VISÃO GERAL </h4>

                                        <li>

                                            <Icon name="calendar" className="icon calendar" />
                                            Tempo de Duração: <span>14 meses</span></li>
                                        <li>

                                            <Icon name="class2" className="icon class2" />
                                            Aulas por Semana: <span>2 (2 horas p/ aula)</span></li>
                                        <li>

                                            <Icon name="time-duration" className="icon time-duration" />

                                            Carga Horária: <span> 224 horas </span></li>
                                        <li>
                                            <Icon name="certificate" className="icon certificate" />
                                            <span>Certificado de qualificação profissional incluso! </span></li>



                                        <li>
                                            <Icon name="books" className="icon books" />
                                            <span>Material didático incluso!</span>

                                        </li>
                                        <li>
                                            <Icon name="moeda-real" className="icon moeda-real" />
                                        Investimento: <span> R$ 160,00 (mensal)</span>
                                        </li>


                                    </div>



                                </div>
                  

                            <div id="modulos-curso-informatica">
                                <h3>Módulos do curso</h3>

                                
                                <NavInformaticaAvancado/>
                                <Hardware />
                                <PowerBI />
                                <Windows />
                                <OfficeAvancado />
                                <Internet />
                                <Rede />
                               
                            </div>
                        </div>

)

}

export default InformaticaEssencial;