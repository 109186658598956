import React, { useEffect } from "react";
import { Animated } from "react-animated-css";
import imgWindows from "../../../../../../assets/img/informatica/img-modulo-windows.png"; 


import AOS from 'aos';
import 'aos/dist/aos.css'




const WindowsPratico = ()=> {

  
useEffect(()=>{
  AOS.init({ duration: 1000 });

}, [])


 
    return (
      <div className="modulos_informatica " id="modulo-windows" data-aos='fade-right'>
        <div className="modulo_content">
          <div className="bloco_1">
     
              <h1>Windows</h1>

            
              <div className="resumo-content">
                <p>
                Esse módulo visa desenvolver as habilidades necessárias para o aluno se tornar um usuário ágil do Windows 10, 
                explorando as principais ferramentas do sistema operacional, além de atalhos essenciais 
                para o uso diário, dominando do gerenciamento do sistema de forma prática.

                </p>
                <figure>
                  
                <img src={imgWindows}
                />
                
                </figure>
           
              </div>
     
   
      
          </div>
          {/*bloco_1 */}
       
        </div>
      </div>
    );

}

export default WindowsPratico;
