import React, { useEffect } from "react";
import { Animated } from "react-animated-css";


import AOS from 'aos';
import 'aos/dist/aos.css'

import imgRecursosMultimidia from "../../../../../../assets/img/ingles/curso-ingles-kids-recursos-multimidia-seta-cursos.png";

const RecursosMultimidia = () => {

    useEffect(() => {


        AOS.init({ duration: 1000 });


    }, []);

    return (

        <div id="modulo-recursos-multimidia" className="modulo-curso modulos-ingles" data-aos='fade-right'>

            <figure>

                <img src={imgRecursosMultimidia} alt='Curso de inglês kids, com jogos e aplicações em sala para impulsionar o aprendizado em inglês.' />
            </figure>


            <div className="resumo">

                <h2>Recursos Multimídia</h2>

                <p>Utilização de recursos multimídia, como vídeos, aplicativos educativos e jogos online, para complementar o aprendizado de inglês em sala de aula.</p>
        </div>
</div>



    )
}

export default RecursosMultimidia;
