import React, { Component } from "react";
import { Animated } from "react-animated-css";

class Internet extends Component {
  state = {};
  render() {
    return (
      <div className="modulos_informatica " id="modulo-internet">
        <div className="modulo_content">
          <div className="bloco_1">
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1500}
            >
              <h1>Internet</h1>
            </Animated>
            <Animated
              animationIn="slideInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1000}
            >
              <div className="resumo">
                <p>
                Nesse módulo abrangemos os fundamentos, 
                como HTTP e Pesquisas Avançadas, até conceitos avançados, como IPs, Internet das Coisas e Nuvem.
                 Abordaremos segurança contra Malwares, o papel dos Cookies, Extensões e Personalização de 
                 Navegadores. Exploraremos os bastidores da internet com Conceitos de Servidores, 
                 discutiremos Aplicativos Online, Contas Integradas além da utilização 
                 do Acesso Remoto. 

                  <Animated
                animationIn="slideInRight"
                animationOut="fadeOut"
                isVisible={true}
                animationInDuration={1000}
              >
                <img
                  src={require("../../../../../../assets/img/informatica/modulo_internet.png")}
                />
              </Animated>

                </p>
              </div>
            </Animated>
            <Animated
              animationIn="fadeInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1200}
            >
              <div className="conteudo">
                <h3>Conteúdo Programático</h3>
                <div>
                
              
                
               
                  <li>HTTP</li>
         
                  <li>Pesquisas Avançadas</li>
                  <li>Links</li>
           
                  <li>IP</li>

                
                  <li>IOT (Internet das Coisas)</li>
                  <li>Conceitos de Nuvem</li>
                  <li>Malwares e Virus</li>
                  <li>Cookies</li>
                  <li>Extesões</li>
             
                  <li>Personalização de Navegadores</li>
                  <li>Conceito de Servidores</li>
                  <li>Aplicativos Online</li>
                  <li>Contas Integradas</li>
                  <li>Acesso Remoto</li>
                </div>
              </div>
            </Animated>
          </div>
       
        </div>
      </div>
    );
  }
}

export default Internet;
