import React, { useState, useRef, useEffect } from 'react';


import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

import ImgThumbNavInglesCompleto from '../../../../../assets/img/ingles/curso-ingles-completo-seta-cursos-thumb.png';
import ImgThumbNavInglesKids from '../../../../../assets/img/ingles/curso-ingles-kids-seta-cursos-thumb.png';




const NavCursosPorCategoria = ({ handleButtonClickShowComponent }) => {

    const addClassItemActiveNavCurso = ()=>{
   
        const pathname = window.location.href; 
        const url = pathname.split('?')[0]; // Remove everything after '?' including '?'
    const urlArr = url.split('/');
    const lastItemUrl = urlArr[urlArr.length - 1];
    console.log(lastItemUrl)
/*
    const navItemActive = document.getElementById('item-curso-'+lastItemUrl);
    navItemActive.classList.add('item-curso-active');
    */
    const navItemActive = document.querySelectorAll('.item-curso-'+lastItemUrl);
    console.log(navItemActive)

    navItemActive.forEach((item)=>{
        item.classList.add('item-curso-active');
    })

    }


    useEffect(()=>{


addClassItemActiveNavCurso();



},[])



    return (
        <div id='bg-nav-cursos-por-categoria' className='bg-nav-cursos-por-categoria'>
            <h2>Conheça nossos <strong>CURSOS DE INGLÊS</strong></h2>
{/*

*/}
            <div  className='item-curso-ingles-completo item-curso item-curso-ingles'>
                <a href='/curso-ingles/ingles-completo' >
                    <figure>                        
                        <img src={ImgThumbNavInglesCompleto} alt='curso-ingles-completo-seta-cursos' />
                        <figcaption>
                        Inglês <strong>Completo</strong>
                        </figcaption>
                    </figure>
                </a>
            </div>


           <div  className='item-curso-ingles-kids item-curso item-curso-ingles'>
                <a href='/curso-ingles/ingles-kids'>
                    <figure>
                    <img src={ImgThumbNavInglesKids} alt='curso-ingles-kids-seta-cursos'/>
                    <figcaption>
                    Inglês <strong>Kids</strong>
                    </figcaption>
                    </figure>
                   
                </a>
            </div>
            {
/**/

            }

 


         

        </div>


    )

}
export default NavCursosPorCategoria;