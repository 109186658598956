import React, { Component } from "react";
import { Animated } from "react-animated-css";

import ImgOfficeAvancado from "../../../../../../assets/img/informatica/modulo-office-avancado.png"; 
class Office extends Component {
  state = {};
  render() {
    return (
      <div className="modulos_informatica " id="modulo-office-avancado">
        <div className="modulo_content">
          <div className="bloco_1">
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1500}
            >
              <h1>Office</h1>
            </Animated>
            <Animated
              animationIn="slideInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1000}
            >
              <div className="resumo">
                <p>
                  O módulo de Office Avançado aprofunda as habilidades dos
                  alunos nos aplicativos Microsoft Office, como Word, Excel, PowerPoint e Outlook.
                  Os participantes aprendem técnicas avançadas de formatação, automação, análise
                  de dados, criação de apresentações impactantes e gerenciamento avançado de e-mails.
                  O curso abrange integração entre aplicativos, automação via macros, scripts e uso de
                  recursos de nuvem para colaboração. Ao final, os alunos desenvolvem competências que
                  os capacitam a utilizar o Microsoft Office de maneira mais eficaz em ambientes profissionais exigentes.
                  <Animated
                    animationIn="slideInRight"
                    animationOut="fadeOut"
                    isVisible={true}
                    animationInDuration={1000}
                  >
                <img src={ImgOfficeAvancado}/>
                  </Animated>
                </p>

              </div>
            </Animated>
            <Animated
              animationIn="fadeInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1200}
            >
              <div className="conteudo">
                <h3>Conteúdo Programático</h3>
                
                  <ul>
                  <span className="span_office">
                    <h4>Word</h4>
                    <li>Formatação Avançada</li>
                    <li>Automação de Documentoss.</li>
                    <li>Controle de Versões</li>
                    <li>Colaboração</li>
                    <li>Documentos Longos:</li>
                    <li>Gráficos e Elementos Visuais</li>

                  </span>
                  <span className="span_office">
                    <h4>Excel</h4>

                    <li>Fórmulas e Funções Avançadas</li>
                    <li>Análise de Dados</li>
                    <li>Automatização</li>
                    <li>Consolidação de Dados</li>
                    <li>Validação de Dados:</li>
                    <li>Ferramentas de Dados</li>


                  </span>
                  <span className="span_office">
                    <h4>Publisher</h4>

                    <li>Layout Criativo</li>
  <li>Elementos Gráficos</li>
  <li>Textos Aprimorados</li>
  <li>Publicações Profissionais</li>
  <li>Integração de Conteúdo</li>
  <li>Ferramentas de Colaboração</li>

  <li>Exportação Versátil</li>


                  </span>

                  <span className="span_office">
                    <h4>Outlook</h4>

                    <li>Gerenciamento Avançado de E-mails</li>
                    <li>Organização de Calendários</li>
                    <li>Tarefas e Lembretes</li>
                    <li>Contatos Avançados</li>
                    <li>Integração com Outros Aplicativos</li>
                    <li>Configuração de Conta</li>

                  </span>
                  </ul>
           
              </div>
            </Animated>
          </div>
          {/*bloco 1 */}


        </div>
      </div>
    );
  }
}

export default Office;
