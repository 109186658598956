
import React from 'react';

import FormNetlify from "../common/formNetlify";
import Info from "../common/info_contatos";
import Icon from "../../assets/icons";


const FormContato =  (props) =>{



    return (
      <div id='bg-form-contato' className={'bg-contato-curso bg-form-contato '+ ' possui-desconto-'+ props.possuiDesconto} data-aos='zoom-in-up' data-aos-duration='900'>
       
         
<div className='title-curso-sem-desconto'>
<h1>Entre em contato conosco e saiba mais!</h1>

</div>
       <div className="bg-desconto bg-desconto-geral"  >
               <div className='desconto-destaque'>
                <span className="desconto-valor">
                        <span class='valor-numero'>{props.descontoPorcento}</span>
                        <span class='porcento-simbolo'>%</span>
                </span>
                <span class='off'>OFF</span>
                    
                </div>
                <div class='desconto-texto'>
                <p>Entre em contato agora mesmo e ganhe <span>{props.descontoPorcento}% de desconto </span>
                no curso de sua escolha!
                </p>
                </div>
          </div>


          <div className="bg-desconto bg-desconto-curso">
               <div className='desconto-destaque'>
                <span className="desconto-valor">
                        <span class='valor-numero'>{props.descontoPorcento}</span>
                        <span class='porcento-simbolo'>%</span>
                </span>
                <span class='off'>OFF</span>
                    
                </div>
                <div class='desconto-texto'>
                <p>
                        Entre em contato agora mesmo e ganhe <span>{props.descontoPorcento}% de desconto </span>
                de <strike>R${props.preco}</strike> por R$<b>{props.precoFinal}</b> (mensal) 
                </p>
                </div>
          </div>


          <div className="info-contato" data-aos='zoom-in-right' data-aos-duration='1200'>

         

            <div class='blocks bg-whatsapp-button'>
             
              <p>
              Entre em contato pelo nosso número ou nos chame no WhatsApp:
      
                  <span className='telefone'>
                  <Icon name="cellphone" className="icon cellphone" />
              
                  {Info.telefone}&nbsp;
                  <Icon name="whatsapp" className="icon whatsapp" />
                  </span>
              
              </p>
              <a class='btn-whatsapp' target='_blank' href="https://wa.me/5511940092529">
                  <Icon name="whatsapp" className="icon whatsapp" /> 
                  Chamar no WhatsApp
              </a>

        </div>

         <div className="blocks bg-redes-sociais">
          <p>Conheça também nossas redes sociais!</p>
            <a className="infoContato" id="face"target="_blank" href={Info.facebook}>
              <Icon name="facebook" className="icon facebook" />
                Seta Cursos
              </a>
              
            <a  className="infoContato" id="insta" target="_blank" href={Info.instagram}>
              <Icon name="instagram" className="icon instagram" /> 
                @Seta Cursos
              </a>
          </div>
          <div className="blocks info-restante">
          <p className="infoContato" id="email">
              <Icon name="email" className="icon email" />

              {Info.email}
            </p>
            <p className="infoContato" id="endereco">
              {Info.endereco}
            </p>

<div className='bg-map'>


<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58433.24167226776!2d-46.72758612692002!3d-23.74461187340801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce49ce5dd65215%3A0x8dcca9f16e4895c1!2sSeta%20Cursos!5e0!3m2!1spt-BR!2sbr!4v1706311605598!5m2!1spt-BR!2sbr" 
  allowfullscreen="" loading="lazy" title="Mapa Endereço Seta Cursos"
referrerpolicy="no-referrer-when-downgrade"></iframe>

</div>
          
            </div> 


     
          </div>
          <div data-aos='zoom-in-up' data-aos-duration='1200'>
          <FormNetlify />
          </div>
      </div>
     
    );
  }


export default FormContato;
