import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import videoDesktop from "../../assets/video/seta-cursos-Ingles-informatica.mp4";
import videoMobile from "../../assets/video/seta-cursos-Ingles-informatica-mobile.mp4";


import { Link as LinkScroll } from "react-scroll";


const SlideHome = () => {
const [videoSlide, setVideoSlide] = useState (videoDesktop); 

useEffect(()=>{

  
    if(window.innerWidth > 700){
setVideoSlide(videoDesktop); 
    }else{

      setVideoSlide(videoMobile); 

    } 
  


},[]);

    return (
      <div id="bgSlideHome">

        <div id="slideHome" >

          <div id="info_slide">
            <h1>Procurando por <strong>Cursos de Inglês</strong>, <strong>Informática</strong> e 
            <strong> Profissionalizantes</strong>?</h1>
            <p>Nós podemos te ajudar!</p>

       
  
                <LinkScroll
                href='#contentPainelCursos'
            className="a-sem-link"

              activeClass="active"
              to="contentPainelCursos"
              spy={true}
              smooth={true}
              offset={0}
              duration={600}
            >
             Conhecer Cursos <span>&#8249;</span>
            </LinkScroll>
          </div>
        <div id="bgScanlines"></div>

         
          <ReactPlayer
            id="video_slide"
            url={[videoSlide]}
            width="100%"
            height="100%"
            loop
            playing
            muted
          ></ReactPlayer>

        </div>

      </div>
    );

};

export default SlideHome;
