import React, {useEffect } from "react";
import { Animated } from "react-animated-css";


import AOS from 'aos';
import 'aos/dist/aos.css'

import imgWriting from "../../../../../../assets/img/ingles/curso-ingles-writing-vocabulario-seta-cursos.png"; 

const Writing = ()=> {
  
  useEffect(() => {
 

    AOS.init({ duration: 1000 });


}, []);

    return (

        <div id="modulo-writing" className="modulo-curso modulos-ingles" data-aos='fade-left'>
        
             
       
          <h2>Writing (Escrita)</h2>
           
              <div className="resumo">
              <figure>

<img src={imgWriting} alt='Curso de inglês. Aprenda a escrever em inglês!'/>
</figure>

              
                                  <p>
                                  No módulo do curso de <strong>inglês completo</strong>, <em>Writing (escrita)</em>, os alunos 
                                  são guiados para desenvolver suas 
                                  habilidades de expressão escrita. Desde a construção de frases simples 
                                  até a redação de ensaios mais complexos, os exercícios práticos abordam <em>gramática</em>, 
                                  <em>vocabulário</em> e <em>estrutura textual</em>. A ênfase está na <strong>clareza</strong>, 
                                  <strong> coesão</strong> e desenvolvimento de <strong>argumentos</strong>, 
                                  preparando os alunos para se comunicarem efetivamente por meio da  
                                  <strong> escrita</strong> em diversos contextos.



                
                </p>
                
                
                  



              </div>
            
            
          </div>

  

    )
}

export default Writing;
