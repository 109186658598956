import React, {useEffect } from "react";
import { Animated } from "react-animated-css";


import AOS from 'aos';
import 'aos/dist/aos.css'

const AutonomiaEscritorio = ()=> {
  
  useEffect(() => {
 

    AOS.init({ duration: 1000 });


}, []);

    return (
      <div className="modulos_informatica " id="modulo-autonomia-escritorio" data-aos='fade-right'>
        <div className="modulo_content">
          <div className="bloco_1">
  
             
       
          <h1>Autonomia em escritório</h1>
           
              <div className="resumo-content">
              
                                  <p>
O módulo "Autonomia em Escritório" visa capacitar os estudantes com habilidades práticas essenciais para o 
ambiente profissional, abrangendo o manuseio de documentos, o uso e configurações de impressora, identificação e 
resolução de problemas, manipulação de arquivos, comunicação e gerenciamento de e-mails. O curso também fornece orientações 
sobre práticas de segurança relacionadas a documentos eletrônicos, dispositivos externos e comunicação por redes.


                
                </p>
                
                
                  
                <figure>

                <img src="https://img.freepik.com/free-photo/young-happy-businessman-working-office-looking-camera-there-are-people-background_637285-10.jpg?w=900&t=st=1703695435~exp=1703696035~hmac=37470770d0833a4aa8627eb3f9810fd7fab63cfb437f7bd94e1dd5b134fe09b5"/>
</figure>



              </div>
            
            
          </div>
     
        </div>
      </div>

    )
}

export default AutonomiaEscritorio;
