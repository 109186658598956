import React, { useEffect } from "react";
import { Animated } from "react-animated-css";
import imgRedes from "../../../../../../assets/img/informatica/img-modulo-redes.png"; 


import AOS from 'aos';
import 'aos/dist/aos.css'


const Rede = ()=> {

  useEffect(()=>{
    AOS.init({ duration: 1000 });
  
  }, [])

    return (
      <div className="modulos_informatica " id="modulo-rede" data-aos='fade-left'>
        <div className="modulo_content">
         
  
       
     
        <div className="bloco_1">

        <h1>Redes</h1>
              <div className="resumo-content">
                <p>
                    Neste módulo, os alunos adquirirão de maneira direta os fundamentos de redes, 
                    concentrando-se inteiramente na resolução de desafios do dia a dia, como configurar 
                    conexões Wi-Fi, solucionar questões comuns de conectividade, e 
                    compreender a comunicação e configuração de redes básicas.
                            
                </p>
                <figure>

                <img src={imgRedes}
                />
                </figure>

              </div>
  
          </div>
          </div>
    
        </div>
    
    );
  
}

export default Rede;
