import React from "react";





import imgMusicaCancoes from "../../../../../../assets/img/ingles/curso-ingles-kids-musica-cancoes-seta-cursos.png"; 

const MusicaCancoes= ()=> {
  


    return (

        <div id="modulo-musica-cancoes" className="modulo-curso modulos-ingles" data-aos='fade-right'>
        
<figure>

<img src={imgMusicaCancoes} alt='Curso de inglês kids, com musicas e canções em inglês em sala de aula.'/>
</figure>
              
           
              <div className="resumo">

<h2>Musica e Canções</h2>
    
                
<p>Introdução de músicas e canções em inglês para ajudar na memorização de vocabulário e na prática da pronúncia.</p>



  


              </div>
            
            
          </div>

  

    )
}

export default MusicaCancoes;
