import React from "react";


import imgIntroducaoRobotica from "../../../../../../assets/img/robotica/curso-robotica-introducao-seta-cursos.png";
import imgModuloComponentes from "../../../../../../assets/img/robotica/curso-robotica-componentes-seta-cursos.png";
import imgModuloEletronica from "../../../../../../assets/img/robotica/curso-robotica-eletronica-basica-seta-cursos.png";
import imgModuloProgramacao from "../../../../../../assets/img/robotica/curso-robotica-programacao-basica-seta-cursos.png";
import imgModuloProjetosAmbientes3d from "../../../../../../assets/img/robotica/curso-robotica-projetos-ambiente-3d-seta-cursos.png";
import imgModuloMontagem from "../../../../../../assets/img/robotica/curso-robotica-montagem-robos-seta-cursos.png";
import imgModuloComunicacaoRobos from "../../../../../../assets/img/robotica/curso-robotica-comunicacao-robos-seta-cursos.png";
import imgModuloRoboticaMovel from "../../../../../../assets/img/robotica/curso-robotica-maker-introducao-robotica-seta-cursos.png";
import imgModuloVisaoComputacional from "../../../../../../assets/img/robotica/curso-robotica-maker-introducao-robotica-seta-cursos.png";
import imgModuloProjetosPraticos from "../../../../../../assets/img/robotica/curso-robotica-projetos-praticos-seta-cursos.png";





const FrasesExpressoes = () => {


    return (

        <>
        
        <div id="modulo-introducao" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Introdução à Robótica</h2>
    <div class="resumo">
      <figure>
        <img src={imgIntroducaoRobotica} alt='Introdução à Robótica'/>
      </figure>
      <p>Neste módulo introdutório, os alunos serão introduzidos ao fascinante mundo da robótica, explorando sua história, conceitos básicos e aplicações modernas.</p>
    </div>
  </div>

  <div id="modulo-componentes" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Componentes Essenciais</h2>
    <div class="resumo">
      <figure>
        <img src={imgModuloComponentes} alt='Componentes Essenciais de um Robô'/>
      </figure>
      <p>Neste módulo, os alunos irão aprender sobre os componentes fundamentais que compõem um robô, incluindo sensores, atuadores e controladores, e como eles interagem para realizar tarefas.</p>
    </div>
  </div>

  <div id="modulo-eletronica" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Eletrônica Básica</h2>
    <div class="resumo">
      <figure>
        <img src={imgModuloEletronica} alt='Eletrônica Básica'/>
      </figure>
      <p>Neste módulo, os alunos serão introduzidos aos princípios básicos da eletrônica, incluindo circuitos, resistores, capacitores e transistores, essenciais para entender o funcionamento dos sistemas robóticos.</p>
    </div>
  </div>

  <div id="modulo-programacao" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Programação Básica</h2>
    <div class="resumo">
      <figure>
        <img src={imgModuloProgramacao} alt='Programação Básica'/>
      </figure>
      <p>Neste módulo, os alunos irão aprender os fundamentos da programação e lógica de programação, essenciais para escrever código para controlar o comportamento dos robôs.</p>
    </div>
  </div>



  <div id="modulo-plataformas" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Projetos em 3D</h2>
    <div class="resumo">
      <figure>
        <img src={imgModuloProjetosAmbientes3d} alt='Plataformas de Hardware'/>
      </figure>
      <p>
      Nesse módulos abordamos a utilização de ferramentas de simulação para modelagem e teste em ambientes virtuais, capacitando os alunos a desenvolver e validar seus projetos antes da implementação física.
      </p>
    </div>
  </div>

  <div id="modulo-montagem" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Montagem e Configuração</h2>
    <div class="resumo">
      <figure>
        <img src={imgModuloMontagem} alt='Montagem e Configuração de Robôs'/>
      </figure>
      <p>Neste módulo prático, os alunos irão aprender como montar e configurar robôs, incluindo a integração de componentes eletrônicos e mecânicos para criar um robô funcional.</p>
    </div>
  </div>

    <div id="modulo-logica-comunicacao" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Lógica de Comunicação</h2>
    <div class="resumo">
      <figure>
        <img src={imgModuloComunicacaoRobos} alt='Programação de Robôs'/>
      </figure>
      <p>
      Neste módulo, os alunos exploram a lógica por trás da comunicação entre dispositivos, 
      abrangendo desde conexões com fio até a transmissão sem fio via Wi-Fi, Bluetooth e infravermelho, etc. 
      Capacitando-os a projetar e desenvolver sistemas robóticos interconectados.
      </p>
    </div>
  </div>



  <div id="modulo-projetos-praticos" class="modulo-curso modulos-robotica" data-aos='fade-right'>
    <h2>Projetos Práticos</h2>
    <div class="resumo">
      <figure>
        <img src={imgModuloProjetosPraticos} alt='Projetos Práticos'/>
      </figure>
      <p>Neste módulo os alunos terão a oportunidade de aplicar todos os conhecimentos adquiridos em projetos práticos, desafios e competições, estimulando a criatividade e a resolução de problemas.</p>
    </div>
  </div>
        
        </>


    )
}

export default FrasesExpressoes;
