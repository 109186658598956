import React from "react";

// Require all components from the 'articles' folder
const requireArticles = require.context('./articles', false, /\.jsx$/);

const articles = requireArticles.keys().map(filename => {
    const component = requireArticles(filename);
    return component.default || component;
  });

const Homepage = ()=>{



    return (

        <div id='homepage-blog' className="pages-blog">
            <section className="section-content-blog">
            <h1>Homepage</h1>
            <div>
    {articles.map((ArticleComponent, index) => (
      <ArticleComponent key={index} />
    ))}
  </div>
            </section>
            <div className="bg-side-menu-blog">
                <h3>Side Menu</h3>
            </div>

        </div>
    );

}

export default Homepage; 