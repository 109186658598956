import React from "react";
 
import ArticleTemplate from "../ArticleTemplate";

const Article = ()=>{

const articleInfo = {
    title:'aaaaaaaccc',
    content: 'bbbbbbbbbbbbb',
}

    return(
        <div className="article">
            aaaaaaaaaaaaaa
            {
                
            <ArticleTemplate articleInfo={articleInfo}/>
            
    
            }
       


         


        </div>
    );


}

export default Article;