import React, { Component, useEffect, useState } from "react";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import LiLinkCursosPage from "./LiLinkCursosPage";
import { useLocation } from 'react-router-dom';

const NavHeader = () => {
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    // Determine if the current page is the homepage
    setIsHomePage(location.pathname === '/'); // Update with the actual path of your homepage


  }, [location.pathname]);


    return (
      <nav id="nav_header" className={isHomePage ? 'nav_header_homepage' : 'nav_header_paginas'}>
        <ul className="ul_homepage">
          <li>
            <LinkScroll
            className="a-sem-link"
            href='#link_top'
            rel='nofollow'
              activeClass="active"
              to="link_top"
              spy={true}
              smooth={true}
              offset={0}
              duration={600}
            >
              Início
            </LinkScroll>
          </li>
          <li>
            <a  href='#' className="a_cursos">
              <LinkScroll
            className="a-sem-link"
        href='#bgPainelCursos'
                activeClass="active"
                to="bgPainelCursos"
                spy={true}
                smooth={true}
                offset={-60}
                duration={600}
              >
                Cursos
              </LinkScroll>

              <ul>
                <li>
                  <LinkScroll
            className="a-sem-link"
                   
            href='#bgPainelCursos'

                    activeClass="active"
                    to="bgPainelCursos"
                    spy={true}
                    smooth={true}
                    offset={-60}
                    duration={600}
                  >
                    Painel de Cursos
                  </LinkScroll>
                </li>

<LiLinkCursosPage/>

                <li id="link_certificadosx">
                  <a href="/certificados"> Certificados</a>
                </li>
              </ul>
            </a>
          </li>
          <li>
            <LinkScroll
                href='#about'
            className="a-sem-link"

              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={600}
            >
              Sobre
            </LinkScroll>
          </li>
          

          <li>
            <a href="/blog/">Blog</a>
          </li>
          


          <li>
            <LinkScroll
             href='#bg-contato-home'
              activeClass="active"
              to="bg-contato-home"
              spy={true}
              smooth={true}
              offset={-60}
              duration={600}
            >
              Contato
            </LinkScroll>
          </li>
        </ul>

        {/* -------------------------------------Menu de multiplas páginas */}
        <ul className="ul_paginas">
          <li>
            <a href="/">Início</a>
          </li>
          <li>
          <a href='#' className="a_cursos">
              <a href="/#bg_informatica">Cursos</a>
              <ul>
          <li>
                  <a href="/#bgPainelCursos">Painel de Cursos</a>
                </li>

                  <LiLinkCursosPage/>

                <li >
                  <a href="/certificados"> Certificados</a>
                </li>

              </ul>
              </a>
     

          </li>

 
          <li >
                  <a href="/#about">Sobre</a>
                </li>

                <li>
            <a href="/blog/">Blog</a>
          </li>

                <li >
                  <a href="/#bg-contato-home">Contato</a>
                </li>
   

        </ul>
      </nav>
    );
  
}

export default NavHeader;
