
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

import Icon from "../../../../../assets/icons/index.js";

import ImgInglesCompleto from '../../../../../assets/img/ingles/curso-ingles-completo-seta-cursos.png';

import Writing from './modulos-ingles-completo/_Writing.js';
import Listening from './modulos-ingles-completo/_Listening.js';
import Talking from './modulos-ingles-completo/_Talking.js';
import Reading from './modulos-ingles-completo/_Reading.js';



const InglesCompleto = ({ callback }) => {

    useEffect(() => {
        const data = {
            possuiDesconto: 'não',
            valor: '160,00',
            desconto: '10',
            valorFinal: '162,00',
        };
        callback(data);
    }, []);


    return (
        <div id='bg-curso-ingles-completo' className="bg-curso-ingles" >
            <Helmet>

                <meta name="description" content="
   Aprenda inglês de maneira eficaz em um curso completo! 
   Aulas práticas e interativas para jovens e adultos. 
   Entre em contato e inicie sua jornada para fluência!
   " />
                <meta name="robots" content="index, follow" />


                {/* Adicione mais meta tags conforme necessário */}
            </Helmet>

            <div className="resumo-curso">

                <h1 className="title">Inglês completo</h1>

                <figure className="bg-curso-image">
                    <img src={ImgInglesCompleto} />
                </figure>

                <p>

                O nosso <strong>Curso Completo de Inglês</strong> foi pensado para levar os alunos do básico ao avançado de uma forma prática e divertida, 
                tanto para os mais jovens quanto para os adultos.

Buscamos assim proporcionar uma experiência completa de aprendizado e um bom ritmo de aprendizado, 
para que o aluno aprenda inglês de uma forma efetiva.</p>

<p>
Com professores experientes em sala cobrimos os quatro pilares essenciais do 
aprendizado em novos idiomas: <em>ouvir</em>, <em>escrever</em>, <em>ler</em> e <em>falar</em>.

Com essa abordagem, queremos que os alunos se sintam à vontade para usar o idioma inglês em diferentes situações, 
atingindo um nível avançado com confiança.</p>

<p>
Cada tópico é organizado de forma cuidadosa para construir uma base sólida e progressiva, permitindo que os alunos desenvolvam suas habilidades ao longo do tempo.

O curso é dividido em três etapas (básico, intermediário e avançado), com duração de um ano cada, e cada etapa vem com sua própria certificação.

                    </p>
                <div id='visao-geral-curso'>
                    <h3>Visão geral do curso: <strong>INGLÊS COMPLETO</strong></h3>

                    <li>

                        <Icon name="calendar" className="icon calendar" />
                        Tempo de Duração: 12 meses (por etapa)</li>
                    <li>

                        <Icon name="class2" className="icon class2" />
                        Aulas por Semana: 2 (2 horas p/ aula)</li>
                    <li>

                        <Icon name="time-duration" className="icon time-duration" />

                        Carga Horária:  192 horas </li>
                    <li>
                        <Icon name="certificate" className="icon certificate" />
                        Certificado de qualificação por etapa incluso! (básico, intermediário, avançado)</li>



                    <li>
                        <Icon name="books" className="icon books" />
                        Material didático incluso!

                    </li>
                    <li>
                        <Icon name="moeda-real" className="icon moeda-real" />
                        Investimento: R$ 160,00 (mensal)
                    </li>


                </div>



            </div>
            <div id='bg-modulos-curso-ingles-completo' className="bg-modulos-curso-ingles bg-modulos-curso">
                <h3>Conheça mais sobre os módulos do curso!</h3>

                <Reading />
                <Writing />
                <Listening />
                <Talking />
            </div>
        </div>



    );

}


export default InglesCompleto;