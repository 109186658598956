import React from "react";


const NotFound404 = ()=>{

    return (

        <div id='NotFound404-blog' className="pages-blog">
            <section className="section-content-blog">
            <br/>
            <br/>
            <br/>
            <br/>
       

            
            <h1>NotFound404</h1>

            </section>
            <div className="bg-side-menu-blog">
                <h3>Side Menu</h3>
            </div>

        </div>
    );

}

export default NotFound404; 