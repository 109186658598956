
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

import Icon from "../../../../../assets/icons/index.js";
import ImgRoboticaMaker from '../../../../../assets/img/robotica/curso-programacao-robotica-seta-cursos.png';
import IntroducaoRobotica from "./modulos-robotica-maker/_IntroducaoRobotica.js";



const RoboticaMaker = ({ callback }) => {

    useEffect(() => {
        const data = {
            possuiDesconto: 'não',
            valor: '160,00',
            desconto: '10',
            valorFinal: '162,00',
        };
        callback(data);
    }, []);


    return (
        <div id='bg-curso-robotica-maker' className="bg-curso-robotica" >



            <Helmet>




                <meta name="robots" content="index, follow" />

               
      
<title>Seta Cursos</title>







                {/* Adicione mais meta tags conforme necessário */}
            </Helmet>

            <div className="resumo-curso">

                <h1 className="title">Robótica Maker</h1>

                <figure className="bg-curso-image">
                    <img src={ImgRoboticaMaker} />
                </figure>

                <p>

                Descubra o fascinante mundo da robótica! Junte-se a nós neste curso dinâmico e envolvente, projetado especialmente para jovens. Aprenda desde os conceitos básicos até a construção e programação de robôs, em um ambiente de aprendizado divertido e prático. Prepare-se para explorar novas fronteiras da tecnologia e desenvolver habilidades que vão impulsionar o seu futuro. Inscreva-se agora e comece a construir o seu próprio futuro robótico!
                 </p>
                 <p>Nosso curso é dividido em 2 etapas (Robótica Maker I e Robótica Maker II), cada uma com duração de 12 meses. </p>
                <div id='visao-geral-curso'>
                    <h3>Visão geral do curso: <strong>Robótica Maker</strong></h3>

                    <li>

                        <Icon name="calendar" className="icon calendar" />
                        Tempo de Duração: 12 meses (por etapa)</li>
                    <li>

                        <Icon name="class2" className="icon class2" />
                        Aulas por Semana: 2 (2 horas p/ aula)</li>
                    <li>

                        <Icon name="time-duration" className="icon time-duration" />

                        Carga Horária:  192 horas </li>
                    <li>
                        <Icon name="certificate" className="icon certificate" />
                        Certificado de qualificação por etapa incluso!</li>



                    <li>
                        <Icon name="books" className="icon books" />
                        Material didático incluso!

                    </li>
                    <li>
                        <Icon name="moeda-real" className="icon moeda-real" />
                        Investimento: R$ 180,00 (mensal)
                    </li>


                </div>



            </div>
            <div id='bg-modulos-curso-robotica-maker' className="bg-modulos-curso-robotica bg-modulos-curso">
                <h3>Conheça mais sobre os módulos do curso!</h3>
<IntroducaoRobotica />

            
            </div>
        </div>



    );

}


export default RoboticaMaker;