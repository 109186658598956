import React, { Component, useContext } from "react";
import { Animated } from "react-animated-css";
import ImageInformatica from "./ImageInformatica";
class Windows extends Component {
  state = {};

  render() {
    return (
      <div className="modulos_informatica " id="modulo-windows">

<h2>Windows Kids  </h2>
          
      
          <div className="resumo">
        

              <figure
          >
            <img
              src='https://sm.ign.com/t/ign_br/articlepage/w/windows-10/windows-10-upgrade-free-to-pirated-windows-users_qdh4.1280.jpg'
            />
          </figure>
          <p>
          
O módulo "Windows Kids" do nosso curso oferece uma abordagem envolvente e adaptada, permitindo 
que as crianças explorem as funcionalidades do sistema de maneira lúdica e interativa.
</p><p>
 O objetivo é  
promover uma compreensão divertida e envolvente, 
capacitando os pequenos para interações seguras e educativas neste ambiente tecnológico fundamental.
            </p>
          </div>

      </div>
    );
  }
}

export default Windows;
