import React, { Component } from "react";
import "./assets/css/style.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import CertificadoValidacao from "./components/pages/CertificadoValidacao";
import ListaCursosIngles from "./components/pages/ListaCursosIngles";
import ListaCursosInformatica from "./components/pages/ListaCursosInformatica";
import Header from "./components/common/Header.js";
import Footer from "./components/common/footer";
import Homepage from "./components/pages/Homepage.js";
import PageFormSubmitted from "./components/common/PageFormSubmitted.js";


import NotFound404 from "./components/NotFound404";

import InformaticaLandingPage from "./components/pages/cursos/landingPages/informatica/InformaticaLandingPage.js";

import InglesLandingPage from "./components/pages/cursos/landingPages/ingles/InglesLandingPage.js";

import RoboticaLandingPage from "./components/pages/cursos/landingPages/robotica/RoboticaLandingPage.js";

import ManutencaoLandingPage from "./components/pages/cursos/landingPages/ManutencaoLandPage.js";
import Blog from "./components/blog/index.js";


const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};
class App extends Component {
  render() {
    return (
      <Router>
        <>
          <div className="container">
            <Header />

            <Switch>

              {/****************************************************************/}


              <Route path="/curso-informatica*">
                <ScrollToTop />
                <InformaticaLandingPage />
              </Route>
              <Route path="/curso-ingles*">
                <ScrollToTop />
                <InglesLandingPage />
              </Route>

              <Route path="/curso-robotica*">
                <ScrollToTop />
                <RoboticaLandingPage/>
              </Route>

              <Route path="/curso-manutencao">

                <ScrollToTop />
                <ManutencaoLandingPage />

              </Route>

              <Route path="/blog">

<ScrollToTop />
<Blog />

</Route>


              <Route path="/certificados">

                <CertificadoValidacao />

              </Route>


              <Route path="/form-submitted">
                <PageFormSubmitted />
              </Route>



              <Route path="/link_ingles_978033445">
                <ListaCursosIngles />
              </Route>


              <Route path="/link_informatica_978033445">

                <ListaCursosInformatica />

              </Route>


              <Route path="/">

                <div id="main_content">
                  <Homepage />
                </div>



              </Route>
              <Route path="/*" component={NotFound404} />
            </Switch>

            <Footer />

          </div>{/* container */}
        </>

      </Router>
    );
  }
}

export default App;
