import React, { useEffect} from "react";
import { Animated } from "react-animated-css";
import imgDigitacao from "../../../../../../assets/img/informatica/img-modulo-digitacao.png"; 



import AOS from 'aos';
import 'aos/dist/aos.css'

const Digitacao = ()=>{

  useEffect(()=>{
    AOS.init({ duration: 1000 });
  
  
  }, [])


    return (
      <div className="modulos_informatica " id="modulo-digitacao" data-aos='fade-left'>
        <div className="modulo_content">
          <div className="bloco_1">

              <h1>Digitacão Proficiente</h1>
     
  
              <div className="resumo-content">
                <p>
               Esse módulo se concentra exclusivamente no aprimoramento das habilidades de digitação dos alunos. km,lllllllllujujujujhuj               Além de abordar técnicas essenciais para digitar com rapidez e precisão, também oferecemos prática intensiva no padrão QWERTY. 
               Os alunos terão a oportunidade de dominar a  arte da digitação eficiente, aprimorando sua velocidade e precisão, 
               para alcançar uma comunicação escrita mais fluente.

    
          
                </p>
    
                <figure>
    
                <img src={imgDigitacao}
                />
                </figure>

              </div>

          

          </div>
   
        </div>
      </div>
    );
  }


export default Digitacao;
