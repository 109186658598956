import React, { Component } from "react";
import { Animated } from "react-animated-css";

const InternetKids
 = () => {


    return (
      <div className="modulos_informatica " id="modulo-internet-kids">

<h2>Internet com Segurança</h2>

<div className="resumo">
        

        <figure>
      <img
        src='https://www.itexperts.com.br/wp-content/uploads/2022/11/25120303/kids.jpg'
      />
    </figure>
    <p>
    

    No módulo "Internet Kids", as crianças são guiadas em uma jornada sobre a navegação segura na internet. 
    Abordamos conceitos vitais, 
    como privacidade online e reconhecimento de conteúdo confiável, fornecendo dicas e orientações práticas. 
    </p>
    <p>
    O objetivo é equipar os pequenos com habilidades essenciais para uma navegação online protegida e consciente, 
    garantindo uma experiência digital positiva e enriquecedora.
      </p>
    </div>



      </div>
    );
  
}

export default InternetKids
;
