import React, { useState, useEffect} from "react";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

import AOS from 'aos';
import 'aos/dist/aos.css'

const Header = () => {

  const [isMobile, setIsMobile] =useState(
    window.innerWidth <= 560
  );

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 560);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts


 

  if (isMobile) {
    return <HeaderMobile />;
  } else {
    return <HeaderDesktop />;
  }
};
export default Header;
