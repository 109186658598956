import React, { Component } from "react";
import { Animated } from "react-animated-css";

const jogosEducativos
 = () => {


    return (
      <div className="modulos_informatica " id="modulo-jogos-educativos">
<h2>Jogos Educativos</h2>

<div className="resumo">
        

        <figure>
      <img
        src='https://img.freepik.com/free-photo/close-up-kid-while-having-technology-education-class_23-2149123764.jpg?w=900&t=st=1702982454~exp=1702983054~hmac=59a1df25ac6197c61ce23c344f62c929f10d43da3ab4c82c56d1e88729b1aff0'
      />
    </figure>
    <p>
      

    O módulo "Jogos Educativos" em nosso curso apresenta uma abordagem envolvente, 
    centrada no aprendizado digital das crianças. 
    Exploramos uma variedade de jogos para estimular habilidades cognitivas, 
    oferecendo desafios que promovem o raciocínio, a resolução de problemas e a 
    compreensão prática da tecnologia. </p><p>
 O objetivo é criar um ambiente envolvente onde as crianças possam aprender de maneira descontraída 
 e inspiradora, utilizando jogos educativos como 
    ferramentas valiosas.
      </p>
    </div>


     
      </div>
    );
  
}

export default jogosEducativos
;
