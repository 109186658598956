import React, { useEffect } from "react";
import { Animated } from "react-animated-css";


import AOS from 'aos';
import 'aos/dist/aos.css'

import imgAvaliacoesAmigaveis from "../../../../../../assets/img/ingles/curso-ingles-kids-avaliacoes-amigaveis-seta-cursos.png";

const Avaliacoesamigaveis = () => {

    useEffect(() => {


        AOS.init({ duration: 1000 });


    }, []);

    return (

        <div id="modulo-avaliacoes-amigaveis" className="modulo-curso modulos-ingles" data-aos='fade-right'>

            <figure>

                <img src={imgAvaliacoesAmigaveis} alt='Curso de inglês kids, professor em sala para ajudar o aluno! ' />
            </figure>


            <div className="resumo">

                <h2>Avaliações Amigáveis</h2>
                <p>Realização de avaliações periódicas de forma amigável e construtiva para acompanhar o progresso das crianças no seu aprendizado de inglês 
                    e identificar áreas de melhoria.</p>

        </div>
</div>



    )
}

export default Avaliacoesamigaveis;
