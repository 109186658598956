import React, {useEffect} from "react";
import Icon from "../../../../../assets/icons/index.js";
import NavInformaticaKids from "./modulos_informatica_kids/navInformaticaKids.js";


import IntroTecKids from "./modulos_informatica_kids/IntroTecKids.js";
import Windows from "./modulos_informatica_kids/Windows.js";
import InternetKids from "./modulos_informatica_kids/InternetKids.js";
import AplicacoesCriativas from "./modulos_informatica_kids/AplicacoesCriativas.js";
import ComponentesKids from "./modulos_informatica_kids/ComponentesKids.js";
import JogosEducativos from "./modulos_informatica_kids/JogosEducativos.js";
import ProgramacaoKids from "./modulos_informatica_kids/ProgramacaoKids.js";



import ImgInformaticaKids from "../../../../../assets/img/informatica/img-informatica-kids-01.png"; 

const InformaticaKids = ({ callback })=>{




    const hideBannerInformatica = ()=>{
        const banner = document.getElementsByClassName('banner-sobre-curso')[0];
        banner.style.display='none'; 
    }

useEffect(()=>{
    const data = {
        possuiDesconto: 'não',
        valor: '160,00',
        desconto: '10',
        valorFinal: '144,00',
    };
    callback(data);

    hideBannerInformatica();
},[]); 

return (
  
     <div className="bg-curso-informatica" id='bg-curso-informatica-kids'>
                            <div id="bg_resumo_informatica" className="resumo-curso">

                                    <h1 className="title">Informática Kids </h1>
                                    <figure className="bg-curso-image">
                                        <img src={ImgInformaticaKids} />
                                    </figure>

                                    <p>
Criado com carinho e dedicação, nosso curso oferece as crianças uma introdução lúdica e educativa ao 
vasto mundo da tecnologia. Por meio de atividades interativas e jogos envolventes, as crianças explorarão 
conceitos fundamentais de informática, ao mesmo tempo em que desenvolvem habilidades essenciais para o século 21.       
                                        
                                        </p>
                                        <div className="etapas-kids">
                      <p>                      
Nosso curso "Informática Kids" é dividido em <span>duas etapas </span> cuidadosamente planejadas.</p>
<p>
 Na <span>primeira etapa</span>, voltada para crianças mais novas, exploramos de maneira lúdica e interativa conceitos fundamentais de informática.
 </p>
 <p>
Na <span>segunda etapa</span>,
 preparamos as crianças para cursos mais avançados e profissionalizantes, oferecendo uma transição suave para um aprendizado mais aprofundado. 
 </p>
 <p>
Buscamos assim proporcionar uma jornada educativa completa, adaptada às diferentes fases de desenvolvimento, capacitando as crianças desde cedo e preparando-as para desafios mais complexos no campo da informática.
</p>                                    </div>

                                    <div id='visao-geral-curso'>
                                        <h4>VISÃO GERAL </h4>

                                        <li>

                                            <Icon name="calendar" className="icon calendar" />
                                            Tempo de Duração: 12 meses (por etapa).</li>
                                        <li>

                                            <Icon name="class2" className="icon class2" />
                                            Aulas por Semana: 2 (2 horas p/ aula).</li>
                                        <li>

                                            <Icon name="time-duration" className="icon time-duration" />

                                            Carga Horária:  196 horas (por etapa).</li>
                                        <li>
                                            <Icon name="certificate" className="icon certificate" />
                                            Certificado Kids de conclusão de etapa!</li>



                                        <li>
                                            <Icon name="books" className="icon books" />
                                            Material didático incluso!

                                        </li>
                                        <li>
                                            <Icon name="moeda-real" className="icon moeda-real" />
                                            Investimento: R$ 160,00 (mensal).
                                        </li>


                                    </div>



                            </div>

                            <div id="modulos-curso-informatica">
                                <h3>Módulos do curso</h3>

                                
<NavInformaticaKids/>
<IntroTecKids />
               
      
                                <Windows />
           
                                <ProgramacaoKids/>
                              

     <AplicacoesCriativas/>    
      

    <ComponentesKids/>
    <JogosEducativos/>  <InternetKids />

                            
                            
                            



                            </div>









                            
                        </div>

)

}

export default InformaticaKids;





