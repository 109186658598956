import React, { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import LiLinkCursosPage from "./LiLinkCursosPage";
import { useLocation } from 'react-router-dom';


import { Animated } from "react-animated-css";

import Icons from "../../assets/icons";
const NavHeaderMobile = () => {
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);


  const handleMenuClick = () => {
    let submenu = document.querySelector("#submenu_mobile");
    submenu.classList.toggle("submenu_closed");

  }


  useEffect(() => {
    // Determine if the current page is the homepage
    setIsHomePage(location.pathname === '/'); // Update with the actual path of your homepage
  }, [location.pathname]);

  return (
    <nav id="nav_header_mobile" className={isHomePage ? 'nav_header_mobile_homepage' : 'nav_header_mobile_paginas'}>
      <ul>
        <li>
          <a id="btn_menu" onClick={handleMenuClick}>
            <Icons id="icon_menu" className="icon" name="menu" />
          </a>
        </li>
        <li>
          <ul id="submenu_mobile" className="submenu_closed" onClick={handleMenuClick}>

            <li>
              <ul className="links_mobile_homepage">
                <li>
                  <Link
                    href='#link_top'
                    onClick={handleMenuClick}
                    activeClass="active"
                    to="link_top"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={600}
                  >
                    <Icons id="icon_menu" className="icon" name="home" />

                    Início
                  </Link>
                </li>

                <li>
                  <Link
                    href='#about'
                    onClick={handleMenuClick}
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={600}
                  >
                    <Icons id="icon_menu" className="icon about" name="about" /> Sobre
                  </Link>
                </li>
     <li>
            <a href="/blog/">
     <Icons id="icon_menu" className="icon" name="blog" />
              
              Blog</a>
          </li>

                <li>
                  <Link
                    href='#bg-contato-home'
                    onClick={handleMenuClick}
                    activeClass="active"
                    to="bg-contato-home"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={600}
                  >
                    <Icons id="icon_menu" className="icon" name="email" />
                    
                    Contato
                  </Link>
                </li>
              </ul>
            </li>
            <ul className="links_mobile_paginas">
              <li>

                <a href="/">
                  <Icons id="icon_menu" className="icon" name="home" />

                  Início

                </a>

              </li>

              <li>
                <a href="/#about">
                  <Icons id="icon_menu" className="icon about" name="about" /> Sobre

                </a>

              </li>

              <li>
            <a href="/blog/">
            <Icons id="icon_menu" className="icon" name="blog" />

              Blog
              </a>
          </li>

              <li>

                <a href="/#bg-contato-home">
                  <Icons id="icon_menu" className="icon" name="email" />
                  Contato
                </a>
              </li>
            </ul>
            <li className="cursos-title-nav-mobile"><span>Cursos</span></li>
            <LiLinkCursosPage />

          </ul>
        </li>
        {/* =================================================== */}
        {/* =================================================== */}
        {/* =================================================== */}


      </ul>
    </nav>
  );

}

export default NavHeaderMobile;
