import React from "react";





import imgHistoriasContos from "../../../../../../assets/img/ingles/curso-ingles-kids-historias-contos-seta-cursos.png"; 

const HistoriasContos= ()=> {
  


    return (

        <div id="modulo-historias-contos" className="modulo-curso modulos-ingles" data-aos='fade-right'>
        
<figure>

<img src={imgHistoriasContos} alt='Curso de inglês kids, histórias e contos para ajudar as crianças no aprendizado de inglês!'/>
</figure>
              
           
              <div className="resumo">

<h2>Histórias e Contos</h2>
    
                
<p>Incorporação de histórias, contos e fábulas em inglês para desenvolver habilidades de audição, compreensão e leitura.</p>



  


              </div>
            
            
          </div>

  

    )
}

export default HistoriasContos;
