import React, { useState, useEffect } from 'react';
import { Link as LinkScroll} from "react-scroll";
import { Helmet } from 'react-helmet';

import 'bootstrap-icons/font/bootstrap-icons.css';

import AOS from 'aos';
import 'aos/dist/aos.css'

import FormContato from '../../../FormContato.js';
import FotosLandPageSlide from '../FotosLandPageSlide.js';
import FormLeadVertical from '../FormLeadVertical.js';

import foto01 from "../../../../../assets/img/informatica/fotos/01-min.jpg";
import foto02 from "../../../../../assets/img/informatica/fotos/02-min.jpg";
import foto03 from "../../../../../assets/img/informatica/fotos/03-min.jpg";
import foto04 from "../../../../../assets/img/informatica/fotos/04-min.jpg";
import foto05 from "../../../../../assets/img/informatica/fotos/05-min.jpg";
import foto06 from "../../../../../assets/img/informatica/fotos/06-min.jpg";
import foto07 from "../../../../../assets/img/informatica/fotos/07-min.jpg";
import foto08 from "../../../../../assets/img/informatica/fotos/08-min.jpg";
import foto09 from "../../../../../assets/img/informatica/fotos/09-min.jpg";
import foto11 from "../../../../../assets/img/informatica/fotos/11-min.jpg";
import foto12 from "../../../../../assets/img/informatica/fotos/12-min.jpg";
import foto13 from "../../../../../assets/img/informatica/fotos/13-min.jpg";

import NavCursosPorCategoria from './NavCursosPorCategoria.js';
import InformaticaEssencial from './InformaticaEssencial.js';
import InformaticaAvancado from './InformaticaAvancado.js';
import InformaticaKids from './InformaticaKids.js';
import InformaticaPratica from './InformaticaPratica.js';

import InformaticaRapida from './InformaticaRapida.js';





const InformaticaLandPage = () => {

    const [dataCurso, setDataCurso] = useState({});

    const [paginaCurso, setPaginaCurso] = useState(null);

    const handleCallbackValorCurso = (data) => {
        setDataCurso(data);
        
      };
    




    useEffect(() => {

        const trocaPaginaCurso = () => {
 // Lógica para determinar qual componente carregar com base na URL
 const pathname = window.location.href;
    
 const url = pathname.split('?')[0]; // Remove everything after '?' including '?'

 if (url.includes('/informatica-rapida')) {
    setPaginaCurso(<InformaticaRapida callback={handleCallbackValorCurso} />);
 } else if (url.includes('/informatica-pratica')) {
    setPaginaCurso(<InformaticaPratica callback={handleCallbackValorCurso}/>);
 } 
 else if (url.includes('/informatica-essencial')) {
    setPaginaCurso(<InformaticaEssencial callback={handleCallbackValorCurso}/>);
 } 

 else if (url.includes('/informatica-avancado')) {
    setPaginaCurso(<InformaticaAvancado callback={handleCallbackValorCurso}/>);
 } 

 else if (url.includes('/informatica-kids')) {
    setPaginaCurso(<InformaticaKids callback={handleCallbackValorCurso}/>);
 } 
 
 else {
   const itemCursoArr =  document.querySelectorAll('.item-curso-informatica-essencial');
   itemCursoArr.forEach((item)=>{
    item.classList.add('item-curso-active');
   }); 
   setPaginaCurso(<InformaticaEssencial callback={handleCallbackValorCurso}/>);
    
   // Defina um componente padrão ou uma mensagem de erro, se necessário
   //setCurrentComponent(<p>404 - Página não encontrada</p>);
 }

        }


        trocaPaginaCurso();

        if(dataCurso.possuiDesconto === 'sim'){
            console.log('SIM');
        }else{
        
            console.log('NOP');
        
        }
        


        AOS.init({ duration: 1000 });


    }, []);

    return (
        <div className='bg-landing-page'>
           
           <Helmet>
   
   <meta name="description" content="
   Descubra o mundo da informática para todas as idades! 
   Nossos cursos abrangem desde noções básicas até tópicos avançados, 
    Sendo cada um projetado para às necessidades e intersses de cada aluno. 
   Entre em contato agora mesmo e saiba mais!
   " />
   <meta name="robots" content="index, follow" />


   {/* Adicione mais meta tags conforme necessário */}
 </Helmet>

            <section class='landing-page' id='informatica-landing-page'>

                <FotosLandPageSlide fotosUrl={[
                    foto01, foto02, foto03, foto04, foto05, foto06, foto07,
                    foto08, foto09, foto11, foto12, foto13,
                ]} />

<NavCursosPorCategoria />

        
                <h1 style={{display:'hidden'}} id='sessao-content-landing-page' ></h1>
                <div id='bg-main-content-landing-page ' className='bg-main-content-landing-page'>

                    <div id='main-content-landing-page' class='main-content-landing-page' data-aos='zoom-in-up' data-aos-duration="4000">
                 
                    {paginaCurso}
        
                    
                    </div>{/*mainContent */}

                    <div class='bg-side-menu' >
                        
                        < FormLeadVertical possuiDesconto={dataCurso.possuiDesconto} descontoPorcento={dataCurso.desconto}  preco={dataCurso.valor} precoFinal={dataCurso.valorFinal} />
                        <NavCursosPorCategoria />

                    </div>{/*sideMenu */}

                </div>
                {/* bg-main-content-landing-page */}
                

                <div class='bg_info_cursos'>
                    <div class='info_cursos'>

                        <div class='banner-sobre-curso' data-aos='fade-up'>

                        
                            <figure data-aos='fade-right' id='img-informatica-02'>
                                <img src={require("../../../../../assets/img/informatica/img-informatica-02.png")} />
                            </figure>
                            <div class='texto-banner'>

                                <h3>Desenvolvendo Habilidades <br /> Digitais para o Futuro</h3>
                                <p>
                                Investir em um curso de informática é uma decisão estratégica
                                 para aproveitar as oportunidades da era digital. Essa formação não apenas
                                 aprimora as habilidades técnicas, mas também abre portas para o mercado de trabalho, 
                                 onde a demanda por profissionais qualificados em informática é alta. 
                                 </p>
                                 <p>
                                 Além de facilitar tarefas diárias, o curso promove a capacidade de adaptação contínua, essencial em um ambiente tecnológico em constante evolução.
                            
                         
                                    Aproveite essa oportunidade e invista no seu futuro! 
                                    <LinkScroll
                                        activeClass="active"
                                        to="sessao-form-contato"
                                        spy={true}
                                        smooth={true}
                                        offset={-300}
                                        duration={600}
                                    > Entre em contato </LinkScroll>
                                    e matricule-se agora mesmo!  🚀 🖥️
                                </p>


                            </div>
                            <figure id='img-informatica-01' data-aos='fade-left'>
                                <img src={require("../../../../../assets/img/informatica/img-informatica-01.png")} />
                            </figure>
                        </div>


                        <div id='bg-contato-curso' class='data-aos-form-horizontal' data-aos='zoom-in-up'>
                            <span style={{ opacity: 0 }} id='sessao-form-contato'>sessao-form-contato</span>


    
                            <FormContato possuiDesconto={dataCurso.possuiDesconto} descontoPorcento={dataCurso.desconto}  preco={dataCurso.valor} precoFinal={dataCurso.valorFinal}/>
    

                        </div>
                        <div>
                        </div>
                    </div>

                </div>

            </section>
        </div>
    )
}
export default InformaticaLandPage; 