import React, { Component } from "react";
import { Animated } from "react-animated-css";


class Hadware extends Component {
  state = {};
  render() {
    return (
      <div className="modulos_informatica " id="modulo-hardware">
        <div className="modulo_content">
          <div className="bloco_1">
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1500}
            >
              <h1>Hardware</h1>
            </Animated>
            <Animated
              animationIn="slideInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1000}
            >
              <div className="resumo">
                <p>
                  Aprenda e amplie seus conhecimentos sobre o hardware de
                  computador entendendo as funções específicas de cada peça e
                  quais são recomendadas na hora de fazer um upgrade em seu
                  computador.

                  <Animated
                animationIn="slideInRight"
                animationOut="fadeOut"
                isVisible={true}
                animationInDuration={1000}
              >
                <img src='https://img.freepik.com/free-photo/top-view-hispanic-technician-fixing-computer-fan-young-man-connecting-new-component-part-cpu_662251-47.jpg?w=740&t=st=1702717831~exp=1702718431~hmac=18b9d8360157083eafc2ac04b9d57af5fcc0df1db6aa1dab013ba3aac010d0ff' />
                {/*
                    <ImageInformatica
                  imgDesktop={"modulo_hardware.png"}
                  imgMobile={"modulo_hardware-mob.png"}
                />
                */}
            
              </Animated>
                </p>
              </div>
            </Animated>
            <Animated
              animationIn="fadeInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1200}
            >
              <div className="conteudo">
                <h3>Conteúdo Programático</h3>
                <ul>

                <h4>Introdução ao Hardware:</h4>

<li>Definição de Hardware</li>
<li>Componentes Essenciais</li>
<li>Placas-mãe e Chipsets</li>
<li>Arquiteturas de CPU</li>
<li>Overclocking e Desempenho</li>
<li>Tipos de Memória (RAM, ROM, Cache)</li>
<li>Gerenciamento de Memória</li>
<li>Upgrade e Expansão</li>
<li>Discos Rígidos (HDD) e SSDs</li>
<li>GPUs e sua Função</li>
<li>Conectividade e Saídas</li>
<li>Gráficos Integrados vs. Dedicados</li>


<li>Funcionalidades e Tecnologias</li>
<li>Conectividade e Saídas de Áudio</li>


<li>Montagem de Computadores</li>
<li>Limpeza e Resolução de Problemas</li>
<li>Atualizações de Hardware</li>
<li>Sistemas de Resfriamento</li>
<li>Prevenção de Sobreaquecimento</li>






                 
                </ul>
              </div>
            </Animated>
          </div>

       
        </div>
      </div>
    );
  }
}

export default Hadware;
