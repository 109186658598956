import React, { useState,  useEffect } from 'react';

import { Helmet } from 'react-helmet';
import 'bootstrap-icons/font/bootstrap-icons.css';

import AOS from 'aos';
import 'aos/dist/aos.css'

import FormContato from '../../../FormContato.js';
import FotosLandPageSlide from '../FotosLandPageSlide.js';
import FormLeadVertical from '../FormLeadVertical.js';


import foto01 from "../../../../../assets/img/informatica/fotos/01-min.jpg";
import foto02 from "../../../../../assets/img/informatica/fotos/02-min.jpg";
import foto03 from "../../../../../assets/img/informatica/fotos/03-min.jpg";
import foto04 from "../../../../../assets/img/informatica/fotos/04-min.jpg";
import foto05 from "../../../../../assets/img/informatica/fotos/05-min.jpg";
import foto06 from "../../../../../assets/img/informatica/fotos/06-min.jpg";
import foto07 from "../../../../../assets/img/informatica/fotos/07-min.jpg";
import foto08 from "../../../../../assets/img/informatica/fotos/08-min.jpg";
import foto09 from "../../../../../assets/img/informatica/fotos/09-min.jpg";
import foto11 from "../../../../../assets/img/informatica/fotos/11-min.jpg";
import foto12 from "../../../../../assets/img/informatica/fotos/12-min.jpg";
import foto13 from "../../../../../assets/img/informatica/fotos/13-min.jpg";
import RoboticaMaker from './_RoboticaMaker.js';


import NavCursosPorCategoria from './NavCursosPorCategoria.js';
import GoogleReview from '../../../../common/_google-review.js';

const InglesLandingPage = () => {

    const [dataCurso, setDataCurso] = useState({});


    const [paginaCurso, setPaginaCurso] = useState(null);



    const handleCallbackValorCurso = (data) => {
        setDataCurso(data);
        
      };
    

    useEffect(() => {
      

        const trocaPaginaCurso = () => {
            // Lógica para determinar qual componente carregar com base na URL
         
            const pathname = window.location.href;
    
            const url = pathname.split('?')[0]; // Remove everything after '?' including '?'
                   
            if (url.includes('/robotica-maker')) {
                
               setPaginaCurso(<RoboticaMaker callback={handleCallbackValorCurso} />);
            } 
           
            else {
            {
                
                         const itemCursoArr =  document.querySelectorAll('.item-curso-robotica-maker');
                itemCursoArr.forEach((item)=>{
                 item.classList.add('item-curso-active');
                }); 
               setPaginaCurso(<RoboticaMaker callback={handleCallbackValorCurso} />);
               
            }
       

            }
           
                     }

                     trocaPaginaCurso();   
           

        AOS.init({ duration: 1000 });


    }, []);


    return (

        <div id='bg-landing-page-robotica' className="bg-landing-page">
            <Helmet>

            <meta property="og:url" content="https://setacursos.com.br/curso-robotica"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Seta Cursos"/>
<meta property="og:description" content=""/>

<meta property="og:image" content="https://ogcdn.net/c078a98b-9a33-4eaf-a5cf-e5ebf3ea450c/v1/setacursos.com.br/Seta%20Cursos/Descubra%20o%20mundo%20da%20rob%C3%B3tica%20em%20nosso%20curso%20especializado%20para%20jovens.%20%20%20%20%20%20Aprenda%20conceitos%20fundamentais%20e%20avan%C3%A7ados%2C%20desde%20programa%C3%A7%C3%A3o%20at%C3%A9%20constru%C3%A7%C3%A3o%20de%20rob%C3%B4s.%20%20%20%20%20%20Aulas%20pr%C3%A1ticas%20e%20divertidas%2C%20projetadas%20para%20jovens%20interessados%20em%20tecnologia%20e%20inova%C3%A7%C3%A3o.%20%20%20%20%20%20Entre%20em%20contato%20para%20come%C3%A7ar%20sua%20jornada%20para%20se%20tornar%20um%20mestre%20da%20rob%C3%B3tica!/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fdocuments%2Fd0d59fa1-8cf7-429f-8771-c3836fb5d7fc.png%3Ftoken%3Dhn-4ZswAm92y5q7W7lzOJrmryE0Bjq7d_JxnMvdnuxE%26height%3D627%26width%3D1200%26expires%3D33243866233/og.png"/>

<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="setacursos.com.br"/>
<meta property="twitter:url" content="https://setacursos.com.br/curso-robotica"/>
<meta name="twitter:title" content="Seta Cursos"/>
<meta name="twitter:description" content=""/>

<meta name="twitter:image" content="https://ogcdn.net/c078a98b-9a33-4eaf-a5cf-e5ebf3ea450c/v1/setacursos.com.br/Seta%20Cursos/Descubra%20o%20mundo%20da%20rob%C3%B3tica%20em%20nosso%20curso%20especializado%20para%20jovens.%20%20%20%20%20%20Aprenda%20conceitos%20fundamentais%20e%20avan%C3%A7ados%2C%20desde%20programa%C3%A7%C3%A3o%20at%C3%A9%20constru%C3%A7%C3%A3o%20de%20rob%C3%B4s.%20%20%20%20%20%20Aulas%20pr%C3%A1ticas%20e%20divertidas%2C%20projetadas%20para%20jovens%20interessados%20em%20tecnologia%20e%20inova%C3%A7%C3%A3o.%20%20%20%20%20%20Entre%20em%20contato%20para%20come%C3%A7ar%20sua%20jornada%20para%20se%20tornar%20um%20mestre%20da%20rob%C3%B3tica!/https%3A%2F%2Fopengraph.b-cdn.net%2Fproduction%2Fdocuments%2Fd0d59fa1-8cf7-429f-8771-c3836fb5d7fc.png%3Ftoken%3Dhn-4ZswAm92y5q7W7lzOJrmryE0Bjq7d_JxnMvdnuxE%26height%3D627%26width%3D1200%26expires%3D33243866233/og.png"/>


            </Helmet>

            <section class='landing-page' id='landing-page-robotica'>

       
                

                <h1 id='sessao-content-landingpage' style={{ display: 'hidden' }} ></h1>

                <div id='bg-main-content-landing-page ' className='bg-main-content-landing-page'>

                    <div id='main-content-landing-page' class='main-content-landing-page' data-aos='zoom-in-up' data-aos-duration="4000">
                   
                        {paginaCurso}

                    </div>{/*mainContent */}

                    <div class='bg-side-menu' >

                        < FormLeadVertical descontoPorcento='10' preco='160,00' precoFinal='144,00' />

                    </div>{/*sideMenu */}

           </div>
 {/* bg-main-content-landing-page */}

 <div id='bg-contato-curso' class='data-aos-form-horizontal' data-aos='zoom-in-up'>
                            <span style={{ opacity: 0 }} id='sessao-form-contato'>sessao-form-contato</span>
 <FormContato possuiDesconto={dataCurso.possuiDesconto} descontoPorcento={dataCurso.desconto}  preco={dataCurso.valor} precoFinal={dataCurso.valorFinal}/>
           </div>
            </section>

           

        </div>

    )
}


export default InglesLandingPage; 
