

import React from "react";
import { Helmet } from 'react-helmet';
import SlideHome from "../common/SlideHome.js";
import GoogleReview from "../common/_google-review.js";

import About from "./about.js";
import ContatoHome from "./ContatoHome.js";
import PainelCursos from "./cursos/PainelCursos.js";



const Homepage = () => {



    return (
      <div id="bg_homepage">
           <Helmet>
   
           <meta name="description" content="Procurando por cursos de Inglês, Informática e Profissionalizantes? 
Você esta no lugar certo! Temos Informática, Inglês, Administração, Programação, Excel Avançado e muito mais!
Clique e conheça nossos cursos!" />

   <meta name="robots" content="index, follow" />
  

   {/* Adicione mais meta tags conforme necessário */}
 </Helmet>

        <div id="link_top"></div>
        {/*Used as anchor link  to scroll page to the top*/}
        <SlideHome />
        <div id="content_homepage">
          <PainelCursos />
          <About />
      {
           
      }


<GoogleReview />


          
          <ContatoHome />

        </div>
      </div>
    );
  
}

export default Homepage;
