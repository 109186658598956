import React, {useEffect} from "react";
import Icon from "../../../../../assets/icons/index.js";

import AOS from 'aos';
import 'aos/dist/aos.css'

import ImgInformatica04 from '../../../../../assets/img/informatica/img-curso-informatica-04.png';


import AutonomiaEscritorio from "./modulos_informatica_pratica/AutonomiaEscritorio.js";

import Windows from "./modulos_informatica_pratica/Windows.js";
import Office from "./modulos_informatica_pratica/Office.js";
import Internet from "./modulos_informatica_pratica/Internet.js";
import Rede from "./modulos_informatica_pratica/Rede.js";

import Digitacao from "./modulos_informatica_pratica/Digitacao.js";

const InformaticaPratica = ({ callback })=>{

    const showHideBannerInformatica = ()=>{
        const banner = document.getElementsByClassName('banner-sobre-curso')[0];
        banner.style.display='block'; 
    }

useEffect(()=>{

    const data = {
        possuiDesconto: 'não',
        valor: '180,00',
        desconto: '10',
        valorFinal: '162,00',
    };
    callback(data);

    showHideBannerInformatica();

    AOS.init({ duration: 1000 });

},[]); 

return (
  
     <div className="bg-curso-informatica" id='bg-curso-informatica-pratica'>
                            <div id="bg_resumo_informatica" className="resumo-curso">

                                    <h1 className="title">Informática Prática</h1>

                                                      <figure className="bg-curso-image">

<img src={ImgInformatica04}/>

</figure>

                                    
<p>O curso de Informática Prática para Adultos destaca-se ao oferecer uma abordagem prática e personalizada, voltada para 
    adultos que buscam ingressar na era digital. Enfatizamos não apenas conceitos básicos, mas também habilidades avançadas 
    relevantes para o mercado de trabalho. O programa visa entender e atender às necessidades específicas de cada aluno, 
    proporcionando um aprendizado personalizado e flexível.
    </p>
    <p>Ao final do curso, os adultos emergem preparados para enfrentar desafios tecnológicos, destacando-se 
    pela competência em softwares de escritório e pela aplicação prática dos conhecimentos adquiridos.</p>

                                    <div id='visao-geral-curso'>
                                        <h3>VISÃO GERAL </h3>

                                        <li>

                                            <Icon name="calendar" className="icon calendar" />
                                            Tempo de Duração: 12 meses</li>
                                        <li>

                                            <Icon name="class2" className="icon class2" />
                                            Aulas por Semana: 2 (2 horas p/ aula)</li>
                                        <li>

                                            <Icon name="time-duration" className="icon time-duration" />

                                            Carga Horária:  196 horas </li>
                                        <li>
                                            <Icon name="certificate" className="icon certificate" />
                                            Certificado de qualificação profissional incluso!</li>



                                        <li>
                                            <Icon name="books" className="icon books" />
                                            Material didático incluso!

                                        </li>
                                        <li>
                                            <Icon name="moeda-real" className="icon moeda-real" />
                                            Investimento: R$ 180,00 (mensal)
                                        </li>


                                    </div>



                            </div>

                            <div id="modulos-curso-informatica">
                                <h3>Módulos recomendados!</h3>

                               

                                <AutonomiaEscritorio/>
                                <Office />
                                <Windows />
                                <Digitacao />
                                <Internet />
                                <Rede />
                                
                            </div>
                        </div>

)

}

export default InformaticaPratica;