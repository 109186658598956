import React  from "react";
import { Link } from 'react-router-dom';

const LiLinkCursosPage = ()=>{


    return(
     <> 

                <li> <Link className="link-cursos" to="/curso-informatica/informatica-essencial">Informática</Link> </li>
                <li> <Link className="link-cursos" to="/curso-ingles/ingles-completo">Inglês</Link> </li>
                <li> <Link className="link-cursos" to="/curso-robotica">Robótica</Link> </li>
                
                <li> <Link className="link-cursos" to="/curso-manutencao">Espanhol</Link> </li>

                <li> <Link className="link-cursos" to="/curso-manutencao">Administração</Link> </li>
                <li> <Link className="link-cursos" to="/curso-manutencao">Desenvolvimento Web</Link> </li>
                <li> <Link className="link-cursos" to="/curso-manutencao">Programação</Link> </li>
                <li> <Link className="link-cursos" to="/curso-manutencao">Banco de Dados</Link> </li>
                <li> <Link className="link-cursos" to="/curso-manutencao">Excel Avançado</Link> </li>
                <li> <Link className="link-cursos" to="/curso-manutencao">Power BI</Link> </li>
                <li> <Link className="link-cursos" to="/curso-manutencao">Media Social</Link> </li>
                <li> <Link className="link-cursos" to="/curso-manutencao">Apoio Escolar</Link> </li>
     
     </>   
    ) 
}


export default LiLinkCursosPage; 
