import React, { Component } from "react";
import ReactDOM from "react-dom";

import Form from "../common/form";
import FormContato from "./FormContato.js";
import Info from "../common/info_contatos";
import Icon from "../../assets/icons/index.js";
import Iframe from "react-iframe";

class ContatoHome extends Component {
  state = {};

  render() {
    return (
      <div className="sessao-page" id="bg-contato-home">
        <div id="contato-home-container">
          <FormContato descontoPorcento='10'/>
        </div>
      </div>
    );
  }
}

export default ContatoHome;
