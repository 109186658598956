import React from "react";





import imgJogosAtividades from "../../../../../../assets/img/ingles/curso-ingles-kids-jogos-atividades-seta-cursos.png"; 

const JogosAtividades= ()=> {
  


    return (

        <div id="modulo-jogos-atividades" className="modulo-curso modulos-ingles" data-aos='fade-right'>
        
<figure>

<img src={imgJogosAtividades} alt='Curso de inglês kids, com jogos e atividades divertida em inglês.'/>
</figure>
              
           
              <div className="resumo">

<h2>Jogos e Atividades</h2>
    
                

<p>Utilização de jogos, brincadeiras e atividades divertidas para tornar o aprendizado do inglês mais interativo e envolvente.</p>


  


              </div>
            
            
          </div>

  

    )
}

export default JogosAtividades;
