import React, { useEffect } from "react";
import { Animated } from "react-animated-css";


import AOS from 'aos';
import 'aos/dist/aos.css'

import imgCulturaTradicoes from "../../../../../../assets/img/ingles/curso-ingles-kids-cultura-tradicoes-seta-cursos.png";

const CulturaTradicoes = () => {

    useEffect(() => {


        AOS.init({ duration: 1000 });


    }, []);

    return (

        <div id="modulo-cultura-tradicoes" className="modulo-curso modulos-ingles" data-aos='fade-right'>

            <figure>

                <img src={imgCulturaTradicoes} alt='Curso de inglês kids com apresentação de diferentes culturas!' />
            </figure>


            <div className="resumo">

                <h2>Culturas e Tradições</h2>
                <p>Também abordamos as culturas e tradições de países de língua inglesa para ampliar o entendimento das crianças sobre o contexto linguístico e cultural do idioma inglês.</p>

        </div>
</div>



    )
}

export default CulturaTradicoes;
