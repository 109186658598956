import React from "react";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";

import Info from "../../../common/info_contatos";
import Icon from "../../../../assets/icons";


const FormLeadVertical = (props)=>{
    return(
        <div id='bg-form-lead-vertical' className={'.bg-form-contato bg-forms-landingpage' + ' possui-desconto-'+ props.possuiDesconto}>



<div className='title-curso-sem-desconto'>
<h3>Entre em contato conosco e saiba mais!</h3>

</div>



<div className="bg-desconto-curso">

        <div class='desconto' >
            <p>
                <span class='valor'>{props.descontoPorcento}</span>
                <span class='porcentagem'>%</span>
                <span class='off'>OFF</span>
            </p>
        </div>
        <div class='textoDesconto'>
            <p>
                Entre em contato agora e ganhe {props.descontoPorcento}% de desconto.</p>
            <p class='cursoPreco'>de <strike>R${props.preco}</strike> por R$<b>{props.precoFinal}</b> (mensal) </p>
        </div>

</div>

        <LinkScroll
        className="btn-go-to-form"
              activeClass="active"
              to="sessao-form-contato"
              spy={true}
              smooth={true}
              offset={-300}
              duration={600}
            >
              Formulário de Contato
            </LinkScroll>
{
/*

<form id='form-lead-vertical' className='form-lead' name='form-lead-horizontal' method="POST" data-netlify="true">
            <div class='div-input'>
                <label for='nome'></label><input placeholder='NOME' type='text' name='nome' />
            </div>
            <div class='div-input'>
                <label for='email' ></label> <input placeholder='DIGITE SEU MELHOR E-MAIL' type='email' name='email' />
            </div>

            <input type='submit' value='GARANTIR MEU CURSO!' />

        </form>
*/

}

        
        <div class='bg-whatsapp-button'>
            <p>
                Se preferir entre em contato agora mesmo pelo número:
                <span className="numero-telefone">  

                <Icon name="cellphone" className="icon cellphone" />
                {Info.telefone}
                <Icon name="whatsapp" className="icon whatsapp" />
                </span>

            </p>

            <a class='btn-whatsapp' target='_blank' href="https://wa.me/5511940092529">
                <Icon name="whatsapp" className="icon whatsapp" /> Chamar no WhatsApp
            </a>

        </div>

    </div>
    )
}

export default FormLeadVertical;