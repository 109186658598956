import React, { Component, useContext } from "react";
import { Animated } from "react-animated-css";
import imgWindows from "../../../../../../assets/img/informatica/img-modulo-windows.png"; 


class Windows extends Component {
  state = {};

  render() {
    return (
      <div className="modulos_informatica " id="modulo-windows">
        <div className="modulo_content">
          <div className="bloco_1">
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1500}
            >
              <h1>Windows</h1>
            </Animated>
            <Animated
              animationIn="slideInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1000}
            >
              <div className="resumo">
                <p>


                  O módulo "Windows" do curso oferece uma abordagem abrangente para aprimorar as habilidades dos participantes no sistema operacional líder mundial. Desde conceitos fundamentais até personalização e otimização de desempenho, os tópicos incluem também PowerShell para automação, segurança online, virtualização com Hyper-V e configuração de redes. Os participantes adquirem conhecimentos práticos para utilizar, proteger e otimizar eficientemente o Windows em diversas situações.

                  <Animated
                    animationIn="slideInRight"
                    animationOut="fadeOut"
                    isVisible={true}
                    animationInDuration={1000}
                  >
                    <img src={imgWindows}
                    />
                  </Animated>

                </p>
              </div>
            </Animated>
            <Animated
              animationIn="fadeInUp"
              animationOut="fadeOut"
              isVisible={true}
              animationInDuration={1200}
            >
              <div className="conteudo">
                <h3>Conteúdo Pragmático</h3>
                <div>

                  <li>Conceitos básicos e estrutura</li>
                  <li>Personalização do sistema</li>
                  <li>Otimização de desempenho</li>
                  <li>Resolução de problemas comuns</li>

                  <li>Introdução à linguagem de script</li>
                  <li>Comandos úteis para automação</li>
                  <li>Criação de scripts simples</li>

                  <li>Ajustes de segurança no Windows</li>
                  <li>Boas práticas para navegação segura</li>
                  <li>Utilização de ferramentas integradas</li>
                  <li>Proteção contra ameaças online</li>

                  <li>Conceitos básicos de virtualização</li>
                  <li>Criação de máquinas virtuais</li>
                  <li>Uso de ambientes isolados para testes</li>

                  <li>Configuração de conexões de rede</li>
                  <li>Solução de problemas comuns</li>
                  <li>Manutenção básica da rede</li>
                  <li>Dicas para uma conectividade estável</li>
                  <li>Introdução a Formatação</li>
                  <li>Instalação do sistema</li>

                </div>
              </div>
            </Animated>
          </div>
          {/*bloco_1 */}

        </div>
      </div>
    );
  }
}

export default Windows;
