
import React, { useEffect } from "react";

import Icon from "../../../../../assets/icons/index.js";

import ImgInglesKids from '../../../../../assets/img/ingles/curso-ingles-kids-seta-cursos.png';

import VocabularioTematico from "./modulos-ingles-kids/_Vocabulario.js";
import FrasesExpressoes from "./modulos-ingles-kids/_FrasesExpressoes.js";
import HistoriasContos from "./modulos-ingles-kids/_HistóriasContos.js";
import JogosAtividades from "./modulos-ingles-kids/_JogosAtividades.js";
import MusicaCancoes from "./modulos-ingles-kids/_MusicaCancoes.js";
import PraticaConversacao from "./modulos-ingles-kids/_PraticaConversacao.js";
import RecursosMultimidia from "./modulos-ingles-kids/_RecursosMultimidia.js";
import AtividadesCriativas from "./modulos-ingles-kids/_AtividadesCriativas.js";
import Avaliacoesamigaveis from "./modulos-ingles-kids/_AvaliacoesAmigaveis.js";
import CulturaTradicoes from "./modulos-ingles-kids/_CulturaTradicoes.js";


const InglesKids = ({ callback }) => {

    useEffect(() => {
        const data = {
            possuiDesconto: 'não',
            valor: '160,00',
            desconto: '10',
            valorFinal: '162,00',
        };
        callback(data);
    }, []);


    return (
        <div id='bg-curso-ingles-kids' className="bg-curso-ingles" >

            <div className="resumo-curso">

                <h1 className="title">Inglês Kids</h1>

                <figure className="bg-curso-image">
                    <img src={ImgInglesKids} />
                </figure>



                <p>

                    No Inglês Kids, nós entendemos o quão importante é para os pais proporcionarem aos seus filhos as melhores 
                    oportunidades de aprendizado. 
                    É por isso que criamos um curso de inglês especialmente projetado para crianças como as suas, 
                    para ajudá-las no aprendizado em inglês de uma forma divertida e envolvente.
                    </p>
                 
                    <p>
                    Cada lição é cuidadosamente elaborada para construir uma base sólida de habilidades linguísticas em seus filhos, enquanto 
                    os mantém envolvidos e entusiasmados com o aprendizado. 
                    </p>
                   

                    <p>
                    Oferecemos até 3 etapas de aprendizado inglês kids, cada um com duração de um ano. 
                    Durante cada etapa nossos professores se dedicam a acompanha a criança em sua jornada de aprendizado, 
                    focada nos quatro pilares essenciais do domínio do inglês: ouvir, escrever, ler e falar.
                    </p>
                    <p>
                    Nosso objetivo é fornecer uma experiência completa de aprendizado de inglês, garantindo que 
                    seus cada criança cresça preparada para utilizar o idioma inglês com confiança e naturalidade.
                    </p>
                    
              

                <div id='visao-geral-curso'>
                    <h3>Visão geral do curso: <strong>INGLÊS KIDS</strong></h3>

                    <li>

                        <Icon name="calendar" className="icon calendar" />
                        Tempo de Duração: 12 meses (por etapa)</li>
                    <li>

                        <Icon name="class2" className="icon class2" />
                        Aulas por Semana: 2 (2 horas p/ aula)</li>
                    <li>

                        <Icon name="time-duration" className="icon time-duration" />

                        Carga Horária:  192 horas </li>
                    <li>
                        <Icon name="certificate" className="icon certificate" />
                        Certificado Kids de conclusão por etapa!
                        </li>



                    <li>
                        <Icon name="books" className="icon books" />
                        Material didático incluso!

                    </li>
                    <li>
                        <Icon name="moeda-real" className="icon moeda-real" />
                        Investimento: R$ 160,00 (mensal)
                    </li>


                </div>



            </div>
            <div id='bg-modulos-curso-ingles-kids' className="bg-modulos-curso-ingles bg-modulos-curso">
                <h3>Conheça mais sobre os módulos do curso!</h3>


<VocabularioTematico/>
<FrasesExpressoes/>

<HistoriasContos/>
<JogosAtividades/>
<MusicaCancoes/>
<PraticaConversacao/>
<RecursosMultimidia />
<AtividadesCriativas/>
<Avaliacoesamigaveis/>
<CulturaTradicoes />




          


            </div>
        </div>



    );

}


export default InglesKids;